import React from 'react';
import { ProductsDetailsFeatureProps } from '../../types/ProductsDetailsFeature';

const ProductsDetailsFeature: React.FC<ProductsDetailsFeatureProps> = ({
  backgroundGradient,
  features,
  productstitle,
  imageSrc,
}) => {
  return (
    <div
      id='features'
      className='features-section section overflow-visible mt-5 pr-lg-5'
      style={{ background: backgroundGradient }}
    >
      <div className='container-fluid'>
        <div className='features-content'>
          <div className='d-flex justify-content-end flex-wrap'>
            <div className='col-lg-7 order-lg-2 mb-lg-4'>
              <div className='section-head text-light text-lg-start'>
                <h2 className='heading text-white'>Amazing Features</h2>
                <b className='text-white font-size-box'>{productstitle}</b>
              </div>
              <div className='row'>
                {features.map((feature, index) => (
                  <div key={index} className={`col-md-6 content-padding`}>
                    <div className='single-feature content-box-size'>
                      <em className={`bi ${feature.icon}`}></em>
                      <h4 className='text-white'>{feature.title}</h4>
                      <p className='text-white font-size-box'>
                        {feature.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className='col-lg-5 pt-100 text-center'>
              <div
                className='fearures-software-mockup wow fadeInLeft'
                data-wow-duration='.5s'
              >
                <img
                  src={imageSrc}
                  alt='software-screen'
                  className='img-fluid'
                  loading='lazy'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsDetailsFeature;
