import mission from '../../assets/img/mission-imgage.jpg';
import culture from '../../assets/img/our-culture.jpg';
import vision from '../../assets/img/vision-image.jpg';
import preview from '../../assets/img/removebg-preview.png';
import { AboutDataItem } from '../../types/AboutDataItem';

export const aboutData: {
  mission: AboutDataItem;
  vision: AboutDataItem;
  culture: AboutDataItem;
  story: AboutDataItem;
} = {
  mission: {
    imgSrc: mission,
    imgAlt: 'Our Mission',
    title: 'Our Mission',
    content: `At Mayora Infotech, our mission is to deliver innovative, scalable, and reliable IT and software solutions that empower businesses to thrive in today's competitive marketplace. We are dedicated to helping small and medium-sized businesses leverage the power of cutting-edge technologies like Next.js, Vue.js, Angular, Python, Blockchain, and AI to create user-friendly, efficient, and cost-effective digital solutions. Our goal is to consistently exceed client expectations by offering products and services that are tailored to meet their unique needs, while ensuring a seamless user experience, operational efficiency, and measurable results.`,
  },

  vision: {
    imgSrc: vision,
    title: 'Our Vision',
    content: `Our vision is to become a globally recognised leader in the IT and software development industry, known for our unwavering commitment to quality, innovation, and customer-centric solutions. We envision a future where Mayora Infotech is at the forefront of technological innovation, helping businesses navigate the evolving digital landscape with confidence.`,
  },
  culture: {
    imgSrc: culture,
    imgAlt: 'Our Culture ',
    title: 'Our Culture',
    content: `At Mayora Infotech, our culture is built on innovation, collaboration, and inclusion. We prioritize teamwork, valuing diverse perspectives to deliver the best solutions. Transparent communication fosters trust and alignment toward shared goals. We embrace perpetual learning to stay ahead in a rapidly evolving industry, encouraging our team to explore new skills and technologies. Above all, we are committed to creating a diverse, inclusive workplace where everyone feels respected and valued.`,
  },
  story: {
    imgSrc: preview,
    imgAlt: 'Story Preview',
    title: 'Who We Are?',
    content: `Since our founding in 2018, Mayora Infotech has been at the forefront of delivering advanced IT and software solutions to businesses worldwide. We have evolved from a small startup into a respected name in the technology sector, known for our expertise in web development, mobile app development, and custom software solutions. We take pride in our ability to solve complex problems through the power of technology. Our clients trust us because we consistently deliver high-quality solutions that meet their specific business needs.`,
  },
};
