import React from 'react';
import heroImage from '../../assets/img/hero-image.png';
import './HeroHeader.css';
import { CustomButton, useNavigateToPath } from '../AllComponents';

const HeroHeader: React.FC = React.memo(() => {
  const navigateToAboutUs = useNavigateToPath('/about-us') ?? '#';
  return (
    <div className='hero-header overflow-hidden px-5'>
      <div className='rotate-img'>
        <div className='rotate-sty-2'></div>
      </div>
      <div className='row gy-5 align-items-center'>
        <div className='col-lg-6'>
          <h3 className='display-5 heding mb-4 '>
            TOP-RATED DEVELOPMENT COMPANY IN INDIA
          </h3>
          <p className='fs-4 mb-4 wow fadeInUp nunito'>
            Meet the team you believe in and get the apps you want. We are here
            as your trusted software development company in India.
          </p>
          <CustomButton
            className='wow fadeInUp ml-0 mt-4 bg-info btn-bg'
            onClick={navigateToAboutUs}
            label={'LEARN MORE'}
          />
        </div>
        <div className='col-lg-6'>
          <img
            src={heroImage}
            className='img-fluid fade-in-out'
            alt='Hero'
            loading='lazy'
            width='fit-content'
            height='fit-content'
          />
        </div>
      </div>
    </div>
  );
});

export default HeroHeader;
