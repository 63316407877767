import React from 'react';

import {
  FaRegHospital,
  FaBuilding,
  FaPlane,
  FaMedkit,
  FaShippingFast,
  FaLaptopHouse,
} from 'react-icons/fa';

import { DiHtml5Multimedia } from 'react-icons/di';

import { PiWatchFill } from 'react-icons/pi';

import { BiSolidTShirt, BiSolidBank } from 'react-icons/bi';

import { MdCastForEducation } from 'react-icons/md';

import { FaCartShopping } from 'react-icons/fa6';

export const industries = [
  { icon: <BiSolidTShirt className='text-primary' />, name: 'Lifestyle' },
  {
    icon: <FaCartShopping className='text-success' />,
    name: 'Retail & E-commerce',
  },
  {
    icon: <FaShippingFast className='text-warning' />,
    name: 'Logistics & Transportation',
  },
  { icon: <FaLaptopHouse className='text-info' />, name: 'Smart Technologies' },
  { icon: <FaRegHospital className='text-danger' />, name: 'Healthcare' },
  {
    icon: <MdCastForEducation className='text-dark' />,
    name: 'Education & E-learning',
  },
  { icon: <BiSolidBank className='text-info' />, name: 'Banking & Financial' },
  {
    icon: <DiHtml5Multimedia className='text-warning' />,
    name: 'Media & Entertainment',
  },
  { icon: <FaBuilding className='text-secondary' />, name: 'Real Estate' },
  { icon: <PiWatchFill className='text-danger' />, name: 'Wearables' },
  { icon: <FaMedkit className='text-success' />, name: 'Hospitality' },
  { icon: <FaPlane className='text-dark' />, name: 'Travel & Tourism' },
];



