import React from 'react';
import { useNavigate } from 'react-router-dom';
import './SiteMap.css';
import mayoralogo from '../../assets/img/logo.png';
import 'animate.css';
import { Breadcrumb } from '../../components/AllComponents';

const Sitemap: React.FC = () => {
  const navigate = useNavigate();

  const navigateToPath = (path: string) => {
    navigate(path);
  };

  return (
    <>
      <Breadcrumb
        title="SiteMap"
        currentPage="SiteMap"
        currentPath="/sitemap"
      />
      <div className="container sitemap">
        <div className="sitemap-container">
          <h1 className="text-primary">SiteMap</h1>
          <ul className="nunito">
            <li>
              <span
                className="sitemap-links nunito"
                onClick={() => navigateToPath('/')}
              >
                Home
              </span>
            </li>
            <li>
              <span
                className="sitemap-links nunito"
                onClick={() => navigateToPath('/about')}
              >
                About Us
              </span>
            </li>
            <div className="sitemap-inner-li">
              <li>
                <span
                  className="sitemap-links nunito"
                  onClick={() => navigateToPath('/services')}
                >
                  Services
                </span>
              </li>
              <li>
                <span
                  className="sitemap-links nunito"
                  onClick={() => navigateToPath('/services')}
                >
                  Mobile App Development
                </span>
              </li>
              <li>
                <span
                  className="sitemap-links nunito"
                  onClick={() => navigateToPath('/services')}
                >
                  Back End Development
                </span>
              </li>
              <li>
                <span
                  className="sitemap-links nunito"
                  onClick={() => navigateToPath('/services')}
                >
                  Website Development
                </span>
              </li>
              <li>
                <span
                  className="sitemap-links nunito"
                  onClick={() => navigateToPath('/services')}
                >
                  Emerging Technologies
                </span>
              </li>
              <li>
                <span
                  className="sitemap-links nunito"
                  onClick={() => navigateToPath('/services')}
                >
                  Digital Marketing
                </span>
              </li>
            </div>
            <div className="sitemap-inner-li">
              <li>
                <span
                  className="sitemap-links nunito"
                  onClick={() => navigateToPath('/products')}
                >
                  Products
                </span>
              </li>
              <li>
                <span
                  className="sitemap-links nunito"
                  onClick={() => navigateToPath('/products/laundry')}
                >
                  Laundry App
                </span>
              </li>
              <li>
                <span
                  className="sitemap-links nunito"
                  onClick={() => navigateToPath('/products/screen-monitor')}
                >
                  Screen Monitor App
                </span>
              </li>
              <li>
                <span
                  className="sitemap-links nunito"
                  onClick={() => navigateToPath('/products/dating')}
                >
                  Dating App
                </span>
              </li>
            </div>
            <li>
              <span
                className="sitemap-links nunito"
                onClick={() => navigateToPath('/blog')}
              >
                Blog
              </span>
            </li>
            <div className="sitemap-inner-li">
              <li>
                <span
                  className="sitemap-links nunito"
                  onClick={() => navigateToPath('/career')}
                >
                  Career
                </span>
              </li>
              <li>
                <span
                  className="sitemap-links nunito"
                  onClick={() => navigateToPath('/career')}
                >
                  ReactJS Developer
                </span>
              </li>
              <li>
                <span
                  className="sitemap-links nunito"
                  onClick={() => navigateToPath('/career')}
                >
                  AngularJS Developer
                </span>
              </li>
              <li>
                <span
                  className="sitemap-links nunito"
                  onClick={() => navigateToPath('/career')}
                >
                  NodeJS Developer
                </span>
              </li>
              <li>
                <span
                  className="sitemap-links nunito"
                  onClick={() => navigateToPath('/career')}
                >
                  React Native Developer
                </span>
              </li>
              <li>
                <span
                  className="sitemap-links nunito"
                  onClick={() => navigateToPath('/career')}
                >
                  iOS Developer
                </span>
              </li>
              <li>
                <span
                  className="sitemap-links nunito"
                  onClick={() => navigateToPath('/career')}
                >
                  Android Developer
                </span>
              </li>
            </div>
            <li>
              <span
                className="sitemap-links nunito"
                onClick={() => navigateToPath('/contact-us')}
              >
                Contact Us
              </span>
            </li>
          </ul>
        </div>
        <div className="sitemap-container-img">
          <img
            src={mayoralogo}
            className="sitemapimg wow animate__animated animate__flip"
            data-wow-iteration="infinite"
            data-wow-duration="6s"
            alt="mayora infotech"
            title="Mayora Infotech"
          />
        </div>
      </div>
    </>
  );
};

export default Sitemap;
