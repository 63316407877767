import React from 'react';
import { FaMapMarkerAlt, FaBriefcase, FaUsers } from 'react-icons/fa';
import './JobCard.css';
import { JobCardProps } from '../../types/JobCardTypes';
import { useNavigate } from 'react-router-dom';

const JobCard: React.FC<JobCardProps> = ({
  id,
  slug,
  title,
  location,
  experience,
  vacancies,
  applyLink,
  routeType,
  description,
}) => {
  const navigate = useNavigate();

  const handleApplyClick = (slug: string) => {
    if (routeType === "special") {
      const googleFormLink = process.env.GOOGLE_FORM_LINK;
      window.open(googleFormLink, "_blank"); 
    } else {
      const jobToNavigate = slug;
            navigate(`job-details/${jobToNavigate}`, {
        state: {
          title,
          location,
          experience,
          vacancies,
          description:
            description && typeof description === 'string' ? description : '',
          applyLink,
        },
      });
    }
  };

  return (
    <div className='col-12 wow fadeInUp' data-wow-delay='0.2s'>
      <div className='card h-100 p-3 job-card'>
        <div className='card-body'>
          <h5 className='card-title '>{title}</h5>
          <div className='row justify-content-between align-items-start'>
            <div className='col-md-7 '>
              <p className='info-text '>
                <FaMapMarkerAlt className='me-2 mb-1' />
                {location}
              </p>
              <p className='text-muted mx-1 mb-2 line-height'>{description}</p>
            </div>
            <div className='col-md-3 d-flex flex-column justify-content-start'>
              <p className='card-text'>
                <FaBriefcase className='me-2 mb-1' /> {experience}
              </p>
              <p className='card-text'>
                <FaUsers className='me-2 mb-1' /> {vacancies}{' '}
                {vacancies === 1 ? 'Vacancy' : 'Vacancies'}
              </p>
              <button
                type='submit'
                onClick={() => handleApplyClick(slug)}
                className='btn applynow-button icon w-75 btn-shiny nunito'
              >
                Apply Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobCard;
