import { FormikHelpers } from 'formik';

export const handlePhoneInputValidation = (
  value: string | undefined,
  setFieldValue: FormikHelpers<any>['setFieldValue'],
  validateField: FormikHelpers<any>['validateField']
): void => {
  const phoneValue = value || '';
  setFieldValue('phone', phoneValue);
  validateField('phone');
};
