import React, { lazy, useState } from 'react';
import { faqData } from '../../utils/MockData/FaqData';
import { Accordion, InfoHeader } from '../AllComponents';
import mayorafaqimage from '../../assets/img/faq-image.png';

const FAQ: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const handleAccordionClick = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <div className='container-fluid FAQ  overflow-hidden '>
      <div className='container '>
        <InfoHeader
          title='Everything You Need to Know About Mayora Infotech'
          description="Find answers to common questions about Mayora Infotech's features, services, customization options, pricing, support, and more. Learn how we empower businesses with innovative tools to streamline operations and achieve success."
        />
        <div className='row g-5 align-items-center'>
          <div className='col-lg-6 wow fadeInUp' data-wow-delay='0.1s'>
            <Accordion
              items={faqData}
              activeIndex={activeIndex}
              onAccordionClick={handleAccordionClick}
            />
          </div>
          <div className='col-lg-6'>
            <div className='FAQ-img RotateMoveRight rounded'>
              <img
                src={mayorafaqimage}
                className='img-fluid w-100'
                alt='About Us'
                loading='lazy'
                width='600'
                height='400'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
