export const footerLinks = [
  {
    title: 'Services',
    links: [
      { path: '/services', label: 'Mobile App Development' },
      { path: '/services', label: 'Back End Development' },
      { path: '/services', label: 'Website Development' },
      { path: '/services', label: 'Emerging Technologies' },
      { path: '/services', label: 'Digital Marketing' },
    ],
  },
  {
    title: 'Products',
    links: [
      { path: '/products', label: 'Laundry App' },
      { path: '/products', label: 'Screen Moniter App' },
      { path: '/products', label: 'Dating App' },
      { path: '/products', label: 'School Diary' },
      { path: '/products', label: 'Whizbot' },
    ],
  },
  {
    title: 'Technology',
    links: [
      { path: '/services', label: 'ReactJS' },
      { path: '/services', label: 'Angular' },
      { path: '/services', label: 'Next.js' },
      { path: '/services', label: 'Node.js' },
      { path: '/services', label: 'React Native' },
      { path: '/services', label: 'iOS' },
      { path: '/services', label: 'Android' },
      { path: '/services', label: 'Python' },
      { path: '/services', label: 'Blockchain' },
      { path: '/services', label: 'Artificial Intelligence' },
    ],
  },
  {
    title: 'Internship Program',
    links: [
      { path: '/career', label: 'Business Development Executive (BDE)' },
      { path: '/career', label: 'Blockchain Developer' },
      { path: '/career', label: 'Python Developer' },
      { path: '/career', label: 'Artificial Intelligence (AI) Developer' },
      { path: '/career', label: 'Node.js Developer' },
    ],
  },
];
