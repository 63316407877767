import React, { useEffect, useState, useRef } from "react";
import "./AchivementsCard.css";
import { AchivementsCardProps } from '../../types/AchivementsCard';

const AchivementsCard: React.FC<AchivementsCardProps> = ({
  value,
  label,
  highlightColor,
}) => {
  const [currentValue, setCurrentValue] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const cardRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isVisible) {
      let start = 0;
      const duration = 4000; 
      const increment = Math.ceil(value / (duration / 16)); 

      const timer = setInterval(() => {
        start += increment;
        if (start >= value) {
          start = value;
          clearInterval(timer);
        }
        setCurrentValue(start);
      }, 16); 

      return () => clearInterval(timer); 
    }
  }, [isVisible, value]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && entry.intersectionRatio >= 0.9) {
          setIsVisible(true); 
        }
      },
      {
        threshold: 0.9, 
      }
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, []);

  return (
    <>
      <div className="container-fluid py-5 d-flex justify-content-center flex-wrap">
        <div className="achivements-card" ref={cardRef}>
          <div className="node-2">
            <div className="heading">
              <span className="text-large">{currentValue}</span>
              <span
                className="text-plus"
                style={{ color: highlightColor || "rgba(255, 118, 117, 1)" }}
              >
                +
              </span>
            </div>
            <p className="text-strength">{label}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AchivementsCard;
