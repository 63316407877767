export const contactDetails = [
  {
    icon: 'fa-home',
    title: 'Address',
    text: 'A-508, Siddhi Vinayak Business Tower, Makarba, Ahmedabad, Gujarat 380051',
  },
  {
    icon: 'fa-phone-alt',
    title: 'Phone',
    text: '+91 7984687892',
  },
  {
    icon: 'fa-envelope-open',
    title: 'Email',
    text: 'info@mayorainfotech.com',
  },
];

export const socialMedia = [
  { platform: 'facebook-f', link: '#' },
  { platform: 'twitter', link: '#' },
  { platform: 'instagram', link: '#' },
  { platform: 'linkedin-in', link: '#' },
];
