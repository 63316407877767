import testimonial_img_1 from '../../assets/img/testimonial-img-1.jpg';
import testimonial_img_2 from '../../assets/img/testimonial-img-2.jpg';
import testimonial_img_3 from '../../assets/img/testimonial-img-3.jpg';

export const testimonials = [
  {
    imgSrc: testimonial_img_1,
    name: 'S lee',
    location: 'New York, USA',
    message:
      'Mayora’s expertise and dedication transformed our project outcomes. Their support and attention to detail made the entire process seamless.',
    stars: 5,
    loading: false,
  },
  {
    imgSrc: testimonial_img_2,
    name: 'Emmac Ollils',
    location: 'San Francisco, USA',
    message:
      'I highly recommend Mayora! Their quality-driven approach and genuine partnership turned our ideas into successful results effortlessly.',
    stars: 4,
    loading: false,
  },
  {
    imgSrc: testimonial_img_3,
    name: 'Carlos Martinez',
    location: 'Chicago, USA',
    message:
      'The team at Mayora delivered exceptional solutions tailored to our needs. Their collaboration and commitment exceeded all expectations.',
    stars: 5,
    loading: false,
  },
];
