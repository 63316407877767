import React from 'react';

type BlogTagsProps = {
  tags: string | string[];
};

const BlogTags: React.FC<BlogTagsProps> = ({ tags }) => {
  const formatTags = (tags: string | string[]): string[] => {
    if (typeof tags === 'string') {
      return tags.split(',').map((tag) => tag.trim());
    }
    return tags as string[];
  };

  const formattedTags = Array.isArray(tags) ? tags : formatTags(tags);

  return (
    <div
      className='blog-tags'
      style={{
        height: 'auto',
        backgroundColor: '#f6f6f6',
        padding: '20px',
      }}
    >
      <h3 className='p-3'>Tags</h3>
      <div className='px-3'>
        {formattedTags.map((tag, index) => (
          <span
            key={index}
            className={`badge bg-primary me-2 mb-2 text-light`}
            style={{ display: 'inline-block', marginRight: '8px' }}
          >
            {tag}
          </span>
        ))}
      </div>
    </div>
  );
};

export default BlogTags;
