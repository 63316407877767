import React, { useEffect, useState } from 'react';
import {
  BlogContent,
  BlogHeader,
  BlogTags,
  Breadcrumb,
  DataNotFound,
  RecentBlogs,
  Spinner,
  UseMetaTags,
} from '../../components/AllComponents';
import { useLocation, useParams } from 'react-router-dom';
import { BlogsDetailsService } from '../../utils/APIsService/APIsServices';
import BlogsReletedblogs from '../../components/BlogsReletedCards/BlogsReletedCards';
import './BlogDetails.css';

const BlogDetails = () => {
  const { state } = useLocation();
  const { slug } = useParams();
  const [blog, setBlog] = useState(state || null);
  const [loading, setLoading] = useState(!state);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!blog && slug) {
      const fetchBlog = async () => {
        try {
          setLoading(true);
          const response = await BlogsDetailsService.getBlogsDetails(slug);
          setBlog(response?.data);
        } catch (err) {
          console.error('Error fetching blog:', err);
          setError('Failed to load blog. Please try again later.');
        } finally {
          setLoading(false);
        }
      };
      fetchBlog();
    }
  }, [blog, slug]);

  UseMetaTags({
    title: blog
      ? `${blog.title} - Mayora Infotech | Insights on IT Solutions`
      : 'Blog Post - Mayora Infotech',
    description: blog
      ? blog.meta_description || `Discover insights on ${blog.title}.`
      : 'Read expert insights and practical tips in our blog posts at Mayora Infotech.',
    keywords: blog
      ? blog.meta_keywords || 'IT solutions, technology insights, blog'
      : 'IT solutions, technology insights, blog',
    author: blog?.author || 'Mayora Infotech',
    language: 'English',
    ogtitle: blog
      ? `${blog.title} - Mayora Infotech`
      : 'Blog Post - Mayora Infotech',
    ogdescription: blog
      ? blog.meta_description ||
        `Discover insights on ${blog.title} and how it benefits your business.`
      : 'Explore expert insights in our latest blog posts.',
    ogImage: blog?.image || '%PUBLIC_URL%/favicon.ico',
    ogtype: 'article',
    ogurl: blog
      ? `https://mayorainfotech.com/blog/${blog.slug}/`
      : 'https://mayorainfotech.com/blog/',
    canonical: blog
      ? `https://mayorainfotech.com/blog/${blog.slug}/`
      : 'https://mayorainfotech.com/blog/',
  });

  return (
    <>
      <Breadcrumb
        title='Blog Details'
        prevPage='Blog'
        prevPath='/blog'
        currentPage={blog?.title || 'Blog Details'}
        currentPath={`/blog/blog-details/${slug}`}
      />
      {loading ? (
        <div className='row g-3 mt-5 justify-content-center d-flex align-items-center api-data-height'>
          <div className='col-12 text-center position-relative'>
            <Spinner background='transparent' fixed={false} translate={false} />
          </div>
        </div>
      ) : error ? (
        <div className='col-12 text-center'>
          <DataNotFound />
        </div>
      ) : blog ? (
        <>
          <BlogHeader
            tags={blog.tags || ['Default Tag']}
            title={blog.title || 'Blog'}
            author={blog.author || 'Unknown Author'}
            authorImg={
              blog.authorImg ||
              'https://static.thenounproject.com/png/4595376-200.png'
            }
            blogImg={
              blog.image ||
              'https://static.thenounproject.com/png/4595376-200.png'
            }
          />
          <div className='container mt-5 py-5' style={{ height: 'auto' }}>
            <div className='row'>
              <BlogContent
                description={blog.descraption || []}
                content={blog.content || []}
              />
              <div className='col-md-4 col-sm-12'>
                <div className='sticky-column'>
                  {blog.related_blogs && blog.related_blogs.length > 0 ? (
                    <>
                    <div className='mb-3 recent-blogs-card'>
                     <h3 className='p-3'>Recent Blog Posts</h3>
                    <RecentBlogs blogs={blog.related_blogs} />
                    </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <BlogTags tags={blog.tags || []} />
                </div>
              </div>
            </div>
            {blog.related_blogs && blog.related_blogs.length > 0 && (
              <BlogsReletedblogs relatedBlogs={blog.related_blogs} />
            )}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default BlogDetails;
