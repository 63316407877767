import ConseptIcon from '../../assets/Icons/ConseptIcon';
import VectorIcon from '../../assets/Icons/VectorIcon';
import DevelopmentIcon from '../../assets/Icons/DevelopmentIcon';
import TestingIcon from '../../assets/Icons/TestingIcon';
import DeploymentIcon from '../../assets/Icons/DeploymentIcon';
import MaintenanceIcon from '../../assets/Icons/MaintenanceIcon';

export const agileData = [
  {
    title: 'Concept',
    image: ConseptIcon,
    description:
      'We analyze your vision, define project objectives, and create a roadmap to turn your ideas into reality with clear goals and priorities.',
    delay: '0.1s',
    color: '#F1F8FF',
  },
  {
    title: 'Design',
    image: VectorIcon,
    description:
      'Our expert team crafts wireframes and prototypes, focusing on user-friendly interfaces that align with your brand and enhance user engagement.',
    delay: '0.1s',
    color: '#F1F8FF',
  },
  {
    title: 'Development',
    image: DevelopmentIcon,
    description:
      'We provide expert development services for web, mobile, and custom software solutions. Using the latest technologies, we deliver secure, scalable, and user-focused results tailored to your business needs. Let us help bring your vision to life.',
    delay: '0.1s',
    color: '#F1F8FF',
  },
  {
    title: 'Testing',
    image: TestingIcon,
    description:
      "This is the stage where our project team works closely with the stakeholders to gather all the required details. Here we understand the project's primary concept to give it the right push during the inception stage",
    delay: '0.1s',
    color: '#F1F8FF',
  },
  {
    title: 'Deployment',
    image: DeploymentIcon,
    description:
      'The final product is deployed seamlessly, backed by extensive checks to ensure smooth performance and compatibility across platforms.',
    delay: '0.1s',
    color: '#F1F8FF',
  },
  {
    title: 'Maintenance',
    image: MaintenanceIcon,
    description:
      'We provide long-term support, regular updates, and performance optimization to keep your solution up-to-date and running efficiently.',
    delay: '0.1s',
    color: '#F1F8FF',
  },
];
