import * as Yup from 'yup';
import { isValidPhoneNumber } from 'libphonenumber-js';

export const contactUsValidationSchema = Yup.object({
  name: Yup.string().required('Name is required.'),
  email: Yup.string()
    .email(
      'Invalid email format. Please include a valid domain, e.g., "@ and .com".'
    )
    .required('Email is required.')
    .matches(
      /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/,
      'Invalid email format. Please include a valid domain.'
    ),
  phone: Yup.string()
    .required('Phone number is required.')
    .test(
      'is-valid-phone',
      'Invalid phone number format. Please check the country code and phone number.',
      (value) => {
        return isValidPhoneNumber(value);
      }
    ),
  subject: Yup.string()
    .min(5, 'Subject must be at least 5 characters.')
    .required('Subject is required.'),
  message: Yup.string()
    .min(8, 'Message must be at least 8 characters long.')
    .required('Message is required.'),
});
