import React from 'react';
import { serviceData } from '../../utils/MockData/DevelopmentServices';
import './DevelopmentServices.css';
import { UseWowEffect } from '../AllComponents';

const DevelopmentServices: React.FC = React.memo(() => {
  UseWowEffect()
  return (
    <div className='container-fluid service py-4'>
      <div className='container pt-3'>
        <div className='row g-4 justify-content-center work-item'>
          {serviceData.map(
            ({ image, title, description, backgroundcolor }, index) => (
              <div
                className={`col-md-6 col-lg-4 mb-4 d-flex align-items-stretch wow fadeInUp`}
                data-wow-delay={`${0.2 * index}s`}
                key={index}
              >
                <div
                  className='card card-effect border-0 rounded  h-100'
                  style={{ backgroundColor: backgroundcolor }}
                >
                  <div className='card-body'>
                    <ul className='list-unstyled d-flex justify-content-between mb-0 mt-2'>
                      <li className='mb-0 fw-bold text_nub1'>
                        <h1 className='fw-bold py-2 px-3 rounded '>
                          {index + 1}
                        </h1>
                      </li>
                      <li>
                        {typeof image === 'string' ? (
                          <img
                            src={image}
                            alt={title}
                            className='card-img-top service-image mx-3 my-3'
                            loading='lazy'
                          />
                        ) : (
                          React.createElement(image, {
                            className: `card-img-top service-image mx-3 my-3`,
                          })
                        )}
                      </li>
                    </ul>
                    <h4 className='card-title text-start mb-3'>{title}</h4>
                    <span className='card-text text-start'>{description}</span>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
});

export default DevelopmentServices;
