import feature from '../../assets/img/servay-feature.png';
import {
  faPalette,
  faLaptopCode,
  faBullhorn,
  faBrush,
  faChartLine,
  faToolbox,
} from '@fortawesome/free-solid-svg-icons';
import { FeatureData } from '../../types/OurFeaturesTypes';
import userexperiance from '../../assets/img/user-experiance.jpg';
import productsdesign from '../../assets/img/product-design.jpg';
import digitalmarketing from '../../assets/img/digital-marketing.jpg';
import branding from '../../assets/img/branding.jpg';
import development from '../../assets/img/development.jpg';
import marketing from '../../assets/img/marketing.jpg';

export const featureData: FeatureData[] = [
  {
    id: 1,
    title: 'User Experience',
    content:
      'We design intuitive, user-centered interfaces that provide a seamless experience across all devices. Our UX experts ensure that every digital solution we build is easy to use, engaging, and tailored to the needs of your audience.',
    features: [
      'Recommender systems',
      'Demand prediction',
      'Omnichannel analytics',
      'Lead generation',
      'Dedicated Developers',
      '24/7 Support',
    ],
    imageSrc: userexperiance,
  },
  {
    id: 2,
    title: 'Product Design',
    content:
      'Our team of designers brings your ideas to life with sleek, modern designs that reflect your brand identity and resonate with your users. Whether you need a mobile app or a complex web platform, we ensure that the final product is not only functional but also visually appealing.',
    features: [
      'Design strategy',
      'User Research',
      'Prototyping',
      'UI/UX Design',
      'Testing and feedback',
    ],
    imageSrc: productsdesign,
  },
  {
    id: 3,
    title: 'Digital Marketing',
    content:
      'In addition to software development, we offer comprehensive digital marketing services to help you reach your audience and drive growth. From SEO and content marketing to paid advertising and social media strategies, we ensure that your brand stands out in the crowded digital landscape.',
    features: [
      'Develop engaging content',
      'Monitor Competitors',
      'Test & Optimize',
      'Focus on customer experience',
      'Content marketing',
    ],
    imageSrc: digitalmarketing,
  },
  {
    id: 4,
    title: 'Branding',
    content:
      ' We help businesses build strong, recognisable brands that resonate with their target market. From crafting compelling brand stories to designing logos, we ensure that your brand identity is cohesive and memorable.',
    features: [
      'Brand positioning',
      'Scalable branding solution',
      'Build brand stories',
      'Boost brand recognition',
      'Customer centric focus', 
    ],
    imageSrc: branding,
  },
  {
    id: 5,
    title: 'Development',
    content:
      'Our development services cover everything from web applications to mobile apps, using the latest technologies like React.js, React Native, and Node.js. We create robust, scalable solutions that are designed to meet your business goals and adapt to changing needs.',
    features: [
      'Innovative development',
      'Robust security',
      'Seamless integration',
      'User focused development',
      'Data driven solution',
    ],
    imageSrc: development,
  },
  {
    id: 6,
    title: 'Marketing',
    content:
      ' Our marketing services go beyond digital strategies. We work with you to develop comprehensive marketing plans that align with your business objectives and drive measurable results. Whether it is launching a new product or expanding your customer base, we are here to support your growth.',
    features: [
      'Tailored marketing strategies',
      'Content creation',
      'Market research & Analysis',
      'Lead generation',
      'Campain Management',
    ],
    imageSrc: marketing,
  },
];

export const featureTabs = [
  { id: 1, label: 'User Experience', icon: faPalette, bgClass: 'bg-fa7070' },
  { id: 2, label: 'Product Design', icon: faBrush, bgClass: 'bg-00aeff' },
  {
    id: 3,
    label: 'Digital Marketing',
    icon: faChartLine,
    bgClass: 'bg-c679e3',
  },
  { id: 4, label: 'Branding', icon: faToolbox, bgClass: 'bg-eb6b3d' },
  { id: 5, label: 'Development', icon: faLaptopCode, bgClass: '' },
  { id: 6, label: 'Marketing', icon: faBullhorn, bgClass: 'bg-f78acb' },
];
