import dashboardFinal from '../../assets/img/dashboard-final.webp';
export const tabs = [
  {
    title: 'Get Started',
    description:
      'Consec tetur adip iscing elit labore et tempor incid.',
    image: dashboardFinal,
  },
  {
    title: 'Install Moniter App',
    description:
      'Install dolor sit amet, conse ctetur adipis cing elit.',
    image: dashboardFinal,
  },
  {
    title: 'Start Using Moniter App',
    description:
      'Adipiscing elit, sed do eiusmod temport ut labore.',
    image: dashboardFinal,
  },
  {
    title: 'Get Advanced Options',
    description:
      'Options psum dolor sitan ditiis neque reprehe.',
    image: dashboardFinal,
  },
];
