import React from 'react';
import './DataNotFound.css';

const DataNotFound: React.FC = () => {
  return (
    <main className='py-5 error-page'>
      <div className='container text-center'>
        <div className='eyes d-flex justify-content-center'>
          <div className='eye'>
            <div className='eye__pupil eye__pupil--left'></div>
          </div>
          <div className='eye'>
            <div className='eye__pupil eye__pupil--right'></div>
          </div>
        </div>
        <div className='error-page__heading'>
          <h1 className='error-page__heading-title'>Data Not Found</h1>
        </div>
      </div>
    </main>
  );
};

export default DataNotFound;
