import React from 'react';
import './DevelopmentProcessCard.css';
import developmenticon from '../../assets/img/development-process-icon-64.png';
import { DevelopmentProcessCardprops } from '../../types/DevelopmentProcessCard';

const DevelopmentProcessCard: React.FC<DevelopmentProcessCardprops> = ({
  developmentproccesssummary,
  developmentprocess,
  title,
}) => {
  return (
    <div className='container py-5 development-process-container'>
      <div className='row'>
        <div className='col-12 text-center'>
          <h2 className='title'>
            <span className='highlight'>{title}</span> Development Process
          </h2>
        </div>
      </div>
      <div className='row'>
        <div className='col-12 mb-3'>
          <h5 className='nunito text-center font-sm-text'>
            At our company, we follow a streamlined {developmentproccesssummary}{' '}
            development process to deliver robust and efficient web and mobile
            applications. From ideation to deployment, every step is
            meticulously planned to meet your business needs and exceed
            expectations.
          </h5>
        </div>
      </div>
      <div className='row justify-content-center mt-3'>
        {developmentprocess.map((step, id) => (
          <div key={id} className='col-lg-3 col-md-6 col-sm-12 '>
            <div className='process-card'>
              <div className='mb-2'>
                <img
                  src={developmenticon}
                  alt='developmenticon'
                  className='image-fluid'
                  loading='lazy'
                />
              </div>
              <h5 className='process-card-title'>{step.name}</h5>
              <ul className='card-list'>
                <li dangerouslySetInnerHTML={{ __html: step.description }}></li>
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DevelopmentProcessCard;
