import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './index.css';
import { ScrollToTop, Spinner } from './components/AllComponents';
import {
  Layout,
  Home,
  About,
  Service,
  OurFeatures,
  Blog,
  BlogSingle,
  Products,
  ProductsDetails,
  Contact,
  Careers,
  NoPage,
  JobDetails,
  SiteMap,
  TechnologyDetails,
} from './pages/index';
import 'react-toastify/dist/ReactToastify.css';
import { Toaster } from 'react-hot-toast';

export const App: React.FC = () => {
  return (
    <Router>
      <ScrollToTop />
      <Toaster />
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='about-us' element={<About />} />
          <Route path='services/*' element={<Service />} />
          <Route
            path='/service/detail/:slug'
            element={<TechnologyDetails />}
          />
          <Route path='features' element={<OurFeatures />} />
          <Route path='blog/*' element={<Blog />} />
          <Route path='blog/blog-details/:slug' element={<BlogSingle />} />
          <Route path='products/*' element={<Products />} />
          <Route
            path='products/products-details/:id'
            element={<ProductsDetails />}
          />
          <Route path='contact-us' element={<Contact />} />
          <Route path='career/*' element={<Careers />} />
          <Route path='career/job-details/:slug' element={<JobDetails />} />
          <Route path='sitemap' element={<SiteMap />} />
        </Route>
        <Route path='*' element={<NoPage />} />
      </Routes>
    </Router>
  );
};
