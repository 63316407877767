export const jobApplyDetails = {
  title: 'Senior UI Designer, Apple',
  location: 'New York, USA',
  employmentType: 'Full Time',
  postedDate: '10, July, 2023',
  vacancies: 5,
  experience: '5 Years',
  offeredSalary: '$2000-$3000',
  jobDeadline: '01 July 2024',
  qualification: 'Graduate',
  description: `We are seeking a skilled Part-Time Software Engineer to join our team, specializing in social media content creation for lead generation purposes. The ideal candidate will have a creative flair, technical proficiency, and a strong understanding of social media trends and algorithms. Must be able to work Monday-Friday during EST business hours. This role will be under the ScaledOn brand, but will be working directly with one of our partners as their dedicated Software Engineer.`,
  rolesandresponsibilities: [
    'Design and build web and enterprise application using in ReactJS/Next JS/.Net Core',
    'Collaborate with cross-functional teams to analyze, design, and implement new features.',
    'Follow defined coding rules/conventions defined by the company.',
    'Perform Unit test and ensure proper test coverage as per organizational standard.',
    'Prepare basic design, detail design, execute basic acceptance testing.',
    'Follow review process for peer review to code delivery.',
    'Participate in defined meeting as per company policy.',
    'Senior Software Engineer should pose mindset and ability to lead small team.',
  ],
  requirements: [
    'Excellent knowledge of Relational Databases MYSQL and ORM technologies (JPA, Hibernate).',
    'Strong understanding on Object-Oriented analysis and design using common design patterns.',
    'Need to know advanced in ReactJS/Next JS/.Net Core.',
    'Practical experience in REST & RESTful web services.',
    'Commanding knowledge on Maven, Gradle build tools.',
    'Follow review process for peer review to code delivery.',
    'Participate in defined meeting as per company policy.',
    'Senior Software Engineer should pose mindset and ability to lead small team.',
  ],
  skills: ['Javascript', 'User Interface', 'Problem Solving'],
  workingdayandtime: [
    'Lunch Facilities: Full Subsidize',
    'Salary Review: Yearly',
    'Festival Bonus: 2',
    'This will be a Night Shift work- Timing will be from 11.00 PM to 07.00 AM.',
    'Weekend-Two Days',
    'Monthly on time guaranteed payment',
    'Early Earned Leave, Sick Leave and Casual Leave facility and many more to come soon.',
    'Salary: $1000- $2000 Monthly',
  ],
  companyprofile: [
    'We are seeking a skilled Part-Time Software Engineer to join our team, specializing in social media content creation for lead generation purposes. The ideal candidate will have a creative flair, technical proficiency, and a strong understanding of social media trends and algorithms. Must be able to work Monday-Friday during EST business hours',
  ],
};
