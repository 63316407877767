import React from 'react';
import {
  BlogCard,
  Breadcrumb,
  DataNotFound,
  InfoHeader,
  Spinner,
  useFetch,
  UseMetaTags,
} from '../../components/AllComponents';
import { BlogsService } from '../../utils/APIsService/APIsServices';
import { BlogType } from '../../types/APIs';
import { useNavigate } from 'react-router-dom';

const Blogs: React.FC = () => {
  UseMetaTags({
    title: 'Blog - Mayora Infotech | Insights on IT Solutions and Technology',
    description:
      "Stay updated with the latest insights, trends, and tips in the IT industry through Mayora Infotech's blog. Discover articles on web development, mobile app development, and digital marketing.",
    keywords:
      'IT blog, technology insights, web development, mobile app development, digital marketing, Ahmedabad',
    author: 'Mayora Infotech',
    language: 'English',
    ogtitle: 'Mayora Infotech Blog',
    ogdescription:
      "Explore Mayora Infotech's blog for valuable insights into IT solutions, industry trends, and expert tips to help your business thrive in the digital landscape.",
    ogImage: '%PUBLIC_URL%/favicon.ico',
    ogtype: 'website',
    ogurl: 'https://mayorainfotech.com/blog/',
    canonical: 'https://mayorainfotech.com/blog/',
  });

  const {
    data: blogs,
    loading,
    error,
  } = useFetch<BlogType[]>(async () => {
    const response = await BlogsService.getBlogs();
    return response.data;
  }, true);
  const blogsData = blogs || [];
  
  const navigate = useNavigate();

  const hangleBlogsClick = (slug: string) => {
    navigate(`blog-details/${slug}`);
  };

  return (
    <>
      <Breadcrumb title='Blog' currentPage='Blog' currentPath='/blog' />
      <div className='container-fluid blog mt-4'>
        <div className='py-5'>
          <InfoHeader
            title='Insights & Innovations: The Mayora Infotech Blog'
            description='Welcome to the Mayora Infotech Blog, where we share the latest trends, insights, and innovations in technology to help businesses stay ahead in a rapidly evolving digital landscape.'
          />
          <div className='row g-3 mt-5 justify-content-center api-data-height'>
            {loading ? (
              <div className='text-center position-relative h-50'>
                <Spinner
                  background='transparent'
                  fixed={false}
                  translate={false}
                />
              </div>
            ) : error ? (
              <div className='col-12 text-center'>
                <DataNotFound />
              </div>
            ) : (
              blogsData.map((blog, index) => (
                <BlogCard
                  key={blog.id}
                  id={blog.id}
                  slug={blog.slug}
                  image={blog.image}
                  title={blog.title}
                  description={blog.descraption}
                  date={new Date(blog.created_at).toLocaleDateString()}
                  animationDelay={`${index * 0.2}s`}
                  onClick={() => hangleBlogsClick(blog.slug)}
                />
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Blogs;
