import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { NavLink } from 'react-router-dom';
import { settings } from '../../utils/MockData/BlogMembers';
import '../../pages/Blog/Blog.css';
import { BlogRelatedCardsProps } from '../../types/BlogRelatedCardsTypes';

const BlogsReletedblogs: React.FC<BlogRelatedCardsProps> = ({
  relatedBlogs,
}) => {

  const dynamicSettings = {
    ...settings,
    slidesToShow: relatedBlogs.length > 1 ? 4 : 1,
    infinite: relatedBlogs.length > 1,
    arrows: relatedBlogs.length > 1 ? true : false,
    dots: relatedBlogs.length > 1 ? true : false,
  };

  return (
    <div className='blog wow fadeInUp' data-wow-delay='0.2s'>
      <Slider {...dynamicSettings}>
        {relatedBlogs.map((blog, index) => (
          <div className='row m-auto  '>
            <div
              key={blog.slug}
              className='w-100'
              data-wow-delay={`0.1s`}
              style={{ animationDelay: `${index * 0.2}s` }}
            >
              <div className='blog-item blog-item-custom '>
                <div className='blog-img'>
                  <img
                    src={blog.image}
                    alt={`Blog ${index + 1}`}
                    height='200'
                    width='100%'
                    loading='lazy'
                  />
                </div>
                <div className='blog-content text-dark border p-4'>
                  <h4 className='blog-title'>{blog.title}</h4>
                  <p>{blog.date}</p>
                  <p className='mb-4 nunito-content'>{blog.description}</p>
                  <NavLink
                    to={`/blog/blog-details/${blog.slug}`}
                    className='btn btn-light rounded-pill py-2 px-4 nunito '
                  >
                    Read More
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};
export default BlogsReletedblogs;
