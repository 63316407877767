import React from 'react';
import './TestimonialCard.css';
import { TestimonialCardProps } from '../../types/TestimonialTypes';

const TestimonialCard: React.FC<TestimonialCardProps> = ({
  imgSrc,
  name,
  description,
}) => {
  return (
    <div className='testimonial-card'>
      <div className='card-content'>
        <p className='message'>"{description}"</p>
        <div className='avatar'>
          <img src={imgSrc} alt={name}  loading='lazy'/>
        </div>
        <p className='name'>{name}</p>
      </div>
    </div>
  );
};

export default TestimonialCard;
