import React, { Suspense } from 'react';
import { useFormik } from 'formik';
import { contactDetails } from '../../utils/MockData/Contact';
import './Contact.css';
import {
  Breadcrumb,
  InfoHeader,
  InputField,
  Spinner,
  UseMetaTags,
} from '../../components/AllComponents';
import toast, { Toaster } from 'react-hot-toast';
import { ContactDetails } from '../../utils/APIsService/APIsServices';
import { NavLink } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { handlePhoneInputValidation } from '../../utils/ValidationSchema/HandlePhoneChange';
import { contactUsValidationSchema } from '../../utils/ValidationSchema/ContactUsValidationSchema';

const LinkdIn = process.env.REACT_APP_MAYORA_LINKDIN;
const Instagram = process.env.REACT_APP_MAYORA_INSTAGRAM;
const WhatsApp = process.env.REACT_APP_MAYORA_WHATSAPP;

const Contact: React.FC = () => {
  UseMetaTags({
    title: 'Contact Us - Mayora Infotech | Get in Touch for IT Solutions',
    description:
      "Reach out to Mayora Infotech for inquiries about our IT services, including web development, mobile app development, and digital marketing. We're here to help you!",
    keywords:
      'contact Mayora Infotech, IT services, web development, mobile app development, digital marketing, Ahmedabad',
    author: 'Mayora Infotech',
    language: 'English',
    ogtitle: 'Contact Mayora Infotech',
    ogdescription:
      'Have questions or need assistance? Contact Mayora Infotech to discuss your IT needs and discover how we can help your business thrive.',
    ogImage: 'https://mayorainfotech.com/assets/contact-image.jpg',
    ogtype: 'website',
    ogurl: 'https://mayorainfotech.com/contact/',
    canonical: 'https://mayorainfotech.com/contact/',
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      subject: '',
      message: '',
    },
    validationSchema: contactUsValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      const contactDataForm = new FormData();
      contactDataForm.append('name', values.name);
      contactDataForm.append('email', values.email);
      contactDataForm.append('phone', values.phone);
      contactDataForm.append('subject', values.subject);
      contactDataForm.append('message', values.message);

      try {
        await ContactDetails.sendMessageApplication(contactDataForm);
        toast.success('Application sent successfully!');
        resetForm();
      } catch (error: any) {
        if (error.response?.status === 422) {
          const serverMessage =
            error.response.data?.message ||
            'Invalid email address. Please enter a valid email.';
          toast.error(serverMessage);
        } else if (error.response?.status === 500) {
          toast.error(
            'The server encountered an issue. Please check your email.'
          );
        } else {
          toast.error('Failed to send the application. Please try again.');
        }
      }
    },
  });

  return (
    <>
      <Breadcrumb title='Contact Us' currentPage='Contact Us' currentPath='/contact-us' />
      <div className='container-fluid contact py-5'>
        <div className='container py-5'>
          <InfoHeader
            title='Get In Touch With Us'
            description='Give us a call or drop by anytime. We endeavor to answer all enquiries within 24 hours on business days. We will be happy to answer your questions.'
          />
          <div className='row g-5'>
            <div className='col-lg-6 animate__zoomInUp'>
              <h2 className='mb-4 nunito contact-us-text'>Contact Form</h2>
              <form onSubmit={formik.handleSubmit}>
                <div className='row g-3'>
                  <div className='col-md-12'>
                    <div className='form-floating'>
                      <InputField
                        label='Your Name'
                        type='text'
                        placeholder='Your Name'
                        name='name'
                        value={formik.values.name}
                        onChange={(e) => {
                          const regex = /^[a-zA-Z\s]*$/;
                          if (regex.test(e.target.value)) {
                            formik.handleChange(e);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        onKeyDown={(e) => {
                          const regex = /^[a-zA-Z\s]$/;
                          if (!regex.test(e.key) && e.key.length === 1) {
                            e.preventDefault();
                          }
                        }}
                        className={`form-control ${
                          formik.touched.name && formik.errors.name
                            ? 'is-invalid'
                            : ''
                        }`}
                      />

                      {formik.touched.name && formik.errors.name && (
                        <small className='text-danger'>
                          {formik.errors.name}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className='col-md-12'>
                    <div className='form-floating'>
                      <InputField
                        label='Your Email'
                        type='email'
                        placeholder='Your Email'
                        name='email'
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={`form-control ${
                          formik.touched.email && formik.errors.email
                            ? 'is-invalid'
                            : ''
                        }`}
                      />
                      {formik.errors.email && formik.touched.email && (
                        <small className='text-danger'>
                          {formik.errors.email}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className='col-md-12'>
                    <div className='form-floating'>
                      <label>Your Phone</label>
                      <PhoneInput
                        international
                        defaultCountry='IN'
                        value={formik.values.phone}
                        onChange={(value) =>
                          handlePhoneInputValidation(
                            value,
                            formik.setFieldValue,
                            formik.validateField
                          )
                        }
                        name='phone'
                        className={`form-control   ${
                          formik.touched.phone && formik.errors.phone
                            ? 'is-invalid'
                            : ''
                        }`}
                      />
                      {formik.touched.phone && formik.errors.phone && (
                        <small className='text-danger'>
                          {formik.errors.phone}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className='form-floating'>
                      <InputField
                        label='Subject'
                        type='text'
                        placeholder='Your Subject'
                        name='subject'
                        value={formik.values.subject}
                        onChange={(e) => {
                          const regex = /^[a-zA-Z\s]*$/;
                          if (regex.test(e.target.value)) {
                            formik.handleChange(e);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        onKeyDown={(e) => {
                          const regex = /^[a-zA-Z\s]$/;
                          if (!regex.test(e.key) && e.key.length === 1) {
                            e.preventDefault();
                          }
                        }}
                        className={`form-control ${
                          formik.touched.subject && formik.errors.subject
                            ? 'is-invalid'
                            : ''
                        }`}
                      />
                      {formik.touched.subject && formik.errors.subject && (
                        <small className='text-danger'>
                          {formik.errors.subject}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className='col-12'>
                    <label htmlFor='message' className='nunito'>
                      Message
                    </label>
                    <div className='form-floating'>
                      <textarea
                        className={`form-control ${
                          formik.touched.message && formik.errors.message
                            ? 'is-invalid'
                            : ''
                        }`}
                        placeholder='Leave a message here...'
                        id='message'
                        name='message'
                        value={formik.values.message}
                        style={{ height: '160px' }}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      ></textarea>
                      {formik.touched.message && formik.errors.message && (
                        <small className='text-danger'>
                          {formik.errors.message}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className='col-12 submit-button'>
                    <button
                      type='submit'
                      className='btn btn-primary w-100 btn-shiny nunito btn-loader '
                      disabled={formik.isSubmitting}
                    >
                      {formik.isSubmitting ? (
                        <Spinner
                          fixed={false}
                          color='#fff'
                          background='transparent'
                          translate={false}
                        />
                      ) : (
                        'Send Message'
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className='col-lg-6'>
              {contactDetails.map((detail, index) => (
                <div
                  key={index}
                  className='d-flex align-items-center mb-4 animate__zoomInUp'
                >
                  <div
                    className='bg-light d-flex align-items-center justify-content-center mb-3'
                    style={{
                      width: '90px',
                      height: '90px',
                      borderRadius: '50%',
                    }}
                  >
                    <i className={`fa ${detail.icon} fa-2x contect-icon`}></i>
                  </div>
                  <div className='ms-4'>
                    <h4>{detail.title}</h4>
                    <span className='mb-0 text-email d-block width-content content-details-width'>
                      {detail.text}
                    </span>
                  </div>
                </div>
              ))}
              <div className='d-flex align-items-center margin-left'>
                {[
                  { icon: 'whatsapp', link: WhatsApp },
                  { icon: 'instagram', link: Instagram },
                  { icon: 'linkedin-in', link: LinkdIn },
                ].map(({ icon, link }, index) => (
                    <NavLink
                      key={icon}
                      className={`btn btn-lg-square icon rounded-circle icon-border ${
                        index === 0 ? 'me-2' : 'mx-2'
                      }`}
                      href={link}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <i className={`fab fa-${icon}`}></i>
                    </NavLink>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster position='top-center' reverseOrder={false} />
    </>
  );
};

export default Contact;
