import React from 'react';
import {
  BlogCard,
  Breadcrumb,
  DataNotFound,
  DevelopmentProcessCard,
  Spinner,
  TechnologyAboutServicesCard,
  TechnologyDetailsHeader,
  TechnologySkills,
  useFetch,
} from '../../components/AllComponents';
import { BlogType, TechnologyDetailsApiResponse } from '../../types/APIs';
import {
  BlogsService,
  TechnologyDetailsService,
} from '../../utils/APIsService/APIsServices';
import { useNavigate, useParams } from 'react-router-dom';
import { technologycard } from '../../utils/MockData/TechnologyDetails';

const TechnologyDetails = () => {
  const { slug } = useParams<{ slug: string }>();
  const {
    data: technology,
    loading: techLoading,
    error: techError,
  } = useFetch<TechnologyDetailsApiResponse>(async () => {
    if (!slug) {
      throw new Error('Slug is missing');
    }
    const response = await TechnologyDetailsService.getTechnologyService(slug);
    return response;
  }, true);

  const technologyData = technology?.data;

  const {
    data: blogs,
    loading: blogsLoading,
    error: blogsError,
  } = useFetch<BlogType[]>(async () => {
    const response = await BlogsService.getBlogs();
    return response.data;
  }, true);

  const blogsData = blogs || [];

  const navigate = useNavigate();

  const handleBlogsClick = (slug: string) => {
    navigate(`/blog/blog-details/${slug}`);
  };

  return (
    <>
      <Breadcrumb
        apititle={technology?.data?.name || 'Service Details'}
        prevPage='Services'
        prevPath='/services'
        currentPage={technology?.data?.name ? technology.data.name : undefined}
        currentPath={
          technology?.data?.slug
            ? `/service/detail/${technology.data.slug}`
            : '/'
        }
      />
      {techLoading || blogsLoading ? (
        <div className='text-center position-relative h-50'>
          <div className='d-flex align-items-center justify-content-center api-data-height'>
            <Spinner background='transparent' fixed={false} translate={false} />
          </div>
        </div>
      ) : techError || blogsError || !technologyData ? (
        <div className='col-12 text-center'>
          <DataNotFound />
        </div>
      ) : (
        <>
          <TechnologyDetailsHeader
            title={technology?.data.name}
            description={technology?.data.description}
            imageSrc={technology?.data.full_banner_image_url || ''}
          />
          <TechnologyAboutServicesCard
            technologycard={technology?.data.driven || technologycard}
            servicecardheading={technology?.data.name}
            servicecardheadingsummary={technology?.data.name}
          />
          <TechnologySkills skills={technology?.data.skills} />
          <DevelopmentProcessCard
            developmentproccesssummary={technology?.data.name}
            developmentprocess={technology?.data.development_processes}
            title={technology?.data.name}
          />
          <div className='row g-3 py-5 justify-content-center'>
            {blogsData.map((blog, index) => (
              <BlogCard
                key={blog.id}
                id={blog.id}
                slug={blog.slug}
                image={blog.image}
                title={blog.title}
                description={blog.descraption}
                date={new Date(blog.created_at).toLocaleDateString()}
                animationDelay={`${index * 0.2}s`}
                onClick={() => handleBlogsClick(blog.slug)}
              />
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default TechnologyDetails;
