import React from 'react';
import { RelatedBlogsprops } from '../../types/RelatedBlogs';
import './RecentBlogs.css';

const RecentBlogs: React.FC<RelatedBlogsprops> = ({ blogs }) => {
  return (
    <div className='related-blogs mb-4'>
      <ul className='list-unstyled'>
        {blogs.map((blog, index) => (
          <li className='d-flex mb-4 gap-2 recent-post-screen' key={index}>
            <img
              src={blog.image}
              alt={blog.title}
              className='me-3 rounded'
              height='100'
              width='100'
              loading='lazy'
            />
            <a href={blog.link} className='text-decoration-none text-secondary'>
              <h5 className='mb-1'>{blog.title}</h5>
              <p className='text-muted mb-0'>{blog.description}</p>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RecentBlogs;
