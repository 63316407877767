import React, { memo } from 'react';
import './CopyRight.css';
import { useNavigateToPath } from '../AllComponents';

const CopyRight: React.FC = () => {
  const navigateToHome = useNavigateToPath('/') ?? '#';
  return (
    <div className='container-fluid copyright py-4'>
      <div className='container'>
        <div className='row g-4 align-items-center'>
          <div className='text-center text-md-start'>
            <span className='text-mayora' onClick={navigateToHome}>
                <i className='fas fa-copyright me-2'></i>
                Mayora Infotech, All rights reserved.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(CopyRight);
