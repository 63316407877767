import React, { useState, useEffect } from 'react';
import './JobDetails.css';
import {
  Breadcrumb,
  DataNotFound,
  JobApplicationForm,
  useFetch,
  UseMetaTags,
} from '../../components/AllComponents';
import { CareersDetailsApiResponse } from '../../types/APIs';
import { useParams } from 'react-router-dom';
import { CareerDetails } from '../../utils/APIsService/APIsServices';
import { jobApplyDetails } from '../../utils/MockData/JobDetails';

const Internshipformlink = process.env.REACT_APP_MAYORA_INTERNSHIP_FORM_LINK;

const JobDetails: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [formData, setFormData] = useState<{ position: string }>({
    position: '',
  });

  useEffect(() => {
    if (isPopupOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isPopupOpen]);

  const openPopup = (title: string) => {
    setFormData({ position: title });
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const {
    data: careersDetails,
    loading,
    error,
  } = useFetch<CareersDetailsApiResponse>(
    () => CareerDetails.getCareersDetails(slug as string),
    !!slug
  );

  const jobDetails = careersDetails?.data || jobApplyDetails;

  const formatDate = (isoDate: string) => {
    if (!isoDate) return '';

    const date = new Date(isoDate);
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    }).format(date);
  };

  UseMetaTags({
    title: jobDetails?.title
      ? `${jobDetails.title} | Mayora Infotech`
      : 'Job Details - Mayora Infotech',
    description: jobDetails?.job_description
      ? `${jobDetails.job_description.substring(0, 150)}...`
      : 'Explore exciting career opportunities at Mayora Infotech.',
    keywords: jobDetails?.tags
      ? jobDetails.tags
          .split(',')
          .map((tag: any) => tag.trim())
          .join(', ')
      : 'IT jobs, Ahmedabad, career, technology, software development',
    author: 'Mayora Infotech',
    language: 'English',
    ogtitle: jobDetails?.title
      ? `${jobDetails.title} | Mayora Infotech`
      : 'Job Details - Mayora Infotech',
    ogdescription: jobDetails?.job_description
      ? `${jobDetails.job_description.substring(0, 150)}...`
      : 'Explore exciting career opportunities at Mayora Infotech.',
    ogImage:
      jobDetails?.image ||
      'https://mayorainfotech.com/assets/careers-image.jpg',
    ogtype: 'website',
    ogurl: `https://mayorainfotech.com/career/${slug}`,
    canonical: `https://mayorainfotech.com/career/${slug}`,
  });

  return (
    <>
      <Breadcrumb title='Job Details' prevPage='Carrer' prevPath='/career' currentPage='industrial-training-program' currentPath='/career/job-details/industrial-training-program' />
      <div className='rts__section section__padding'>
        <div className='container '>
          {error || !jobDetails ? (
            <div className='col-12 text-center'>
              <DataNotFound />
            </div>
          ) : (
            <div className='row g-30'>
              <div className='col-lg-7 col-xl-8'>
                <div className='rts__job__details'>
                  <div
                    className='job__meta w-100 d-flex text-center text-md-start flex-column gap-2 wow fadeInDown'
                    data-wow-delay='0.1s'
                  >
                    <div>
                      <h3 className='job__title h3 mb-0 '>
                        {jobDetails.title}
                      </h3>
                    </div>
                    <div className='d-flex gap-3 justify-content-center justify-content-md-start flex-wrap mb-3 mt-2'>
                      <div className='d-flex gap-2 align-items-center'>
                        <i className='bi bi-geo-alt'></i> {jobDetails.location}
                      </div>
                      <div className='d-flex gap-2 align-items-center'>
                        <i className='bi bi-briefcase'></i>{' '}
                        {jobDetails.experience}
                      </div>
                      <div className='d-flex gap-2 align-items-center'>
                        <i className='bi bi-person'></i> {jobDetails.vacancy}{' '}
                        {jobDetails.vacancy === 1 ? 'Vacancy' : 'Vacancies'}
                      </div>
                    </div>
                  </div>
                  <div className='mb-2 mt-4 wow fadeInUp' data-wow-delay='0.2s'>
                    <h3 className='fw-semibold mb-4 '>Job Description</h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: jobDetails.job_description,
                      }}
                    />
                  </div>
                  <div className='mb-2 wow fadeInUp' data-wow-delay='0.3s'>
                    <h3 className='fw-semibold mb-4 '>
                      Roles and Responsibilities
                    </h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: jobDetails.roles_responsibilities,
                      }}
                    />
                  </div>
                  <div className='mb-2 wow fadeInUp' data-wow-delay='0.4s'>
                    <h3 className='fw-semibold mb-4 '>Required Skills</h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: jobDetails.required_skills,
                      }}
                    />
                  </div>
                  <div className='mb-4 wow fadeInUp' data-wow-delay='0.6s'>
                    <h3 className='fw-semibold mb-4 '>Working Day & Time </h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: jobDetails.working_day_time,
                      }}
                    />
                  </div>
                  {/* <div className='mb-4 wow fadeInUp' data-wow-delay='0.6s'>
                  <h3 className='fw-semibold mb-4 '>Company Profile </h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: jobDetails.company_profile,
                    }}
                  />
                </div> */}
                </div>
              </div>
              <div className='col-lg-5 col-xl-4 d-flex flex-column gap-40'>
                <div
                  className='job__overview wow animate__pulse'
                  data-wow-delay='0.1s'
                >
                  <h3
                    className='fw-semibold mb-20  wow animate__fadeIn'
                    data-wow-duration='5s'
                    data-wow-delay='0.2s'
                    data-wow-iteration='infinite'
                  >
                    Job Overview
                  </h3>
                  <div className='job__overview__content'>
                    <ul className='pl-0 pr-1'>
                      <li className='d-flex flex-wrap gap-3 align-items-center justify-content-between '>
                        <span className='left-text nunito'>
                          <i className='bi bi-calendar'></i> Date Posted:
                        </span>
                        <span className='text nunito'>
                          {formatDate(jobDetails.created_at)}
                        </span>
                      </li>
                      <li className='d-flex flex-wrap gap-3 align-items-center justify-content-between'>
                        <span className='left-text nunito'>
                          <i className='bi bi-person'></i> Vacancy:
                        </span>
                      <span className='text nunito'>{jobDetails.vacancy}</span>
                      </li>
                      <li className='d-flex flex-wrap gap-3 align-items-center justify-content-between'>
                        <span className='left-text nunito'>
                          <i className='bi bi-star'></i> Experience:
                        </span>
                        <span className='text nunito'>
                          {jobDetails.experience}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className='d-flex gap-3'>
                    <button
                      type='submit'
                      className='btn w-100 icon btn-shiny nunito '
                      onClick={() => {
                        if (slug === 'industrial-training-program') {
                          const googleFormLink = Internshipformlink;
                          window.open(googleFormLink, '_blank');
                        } else {
                          openPopup(jobDetails.title);
                        }
                      }}
                    >
                      Apply Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {isPopupOpen && (
          <>
            <JobApplicationForm onClose={closePopup} formData={formData} />
          </>
        )}
      </div>
    </>
  );
};

export default JobDetails;
