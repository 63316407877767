import React, { FC } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import { Spinner, TestimonialCard, useFetch } from '../AllComponents';
import { TestimonialService } from '../../utils/APIsService/APIsServices';
import defaultImage from '../../assets/img/detault2.png';
import { Testimonialsettings } from '../../utils/SliderSettings';

const SwiperComponent: FC = () => {
  const { data, loading, error } = useFetch(
    TestimonialService.getTestimonial,
    true
  );

  const testimonialData = data?.data || [];

  return (
    <div className='slider-container'>
      {loading ? (
        <div className='text-center position-relative h-50'>
          <Spinner background='transparent' fixed={false} translate={false} />
        </div>
      ) : error ? (
       <></>
      ) : testimonialData.length > 0 ? (
        <Slider {...Testimonialsettings}>
          {testimonialData.map((testimonial, index) => (
            <div key={index} className='testimonial-slider'>
              <TestimonialCard
                imgSrc={testimonial.image || defaultImage}
                name={testimonial.name}
                description={testimonial.descraption}
                loading={loading}
              />
            </div>
          ))}
        </Slider>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SwiperComponent;
