import * as React from 'react';
import { IconProps } from '../../types/icon';
const DevOpsIcon = ({ className }: IconProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='512.000000pt'
    height='512.000000pt'
    viewBox='0 0 512.000000 512.000000'
    preserveAspectRatio='xMidYMid meet'
    className={className}
  >
    <g
      transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
      fill='#ed09fb'
      stroke='none'
    >
      <path d='M3440 5114 c-296 -35 -545 -126 -753 -275 -100 -71 -258 -226 -333 -326 -61 -81 -164 -251 -164 -270 0 -17 -17 -16 -27 1 -12 20 -48 36 -83 36 -45 0 -90 -47 -90 -93 0 -44 101 -272 135 -307 38 -37 81 -31 230 37 117 53 132 62 148 96 16 31 17 42 7 72 -15 46 -44 65 -98 65 -52 0 -53 -1 15 112 137 227 323 403 548 515 201 101 359 137 605 137 201 0 314 -19 480 -81 192 -71 327 -161 487 -326 252 -259 367 -550 367 -927 0 -377 -114 -666 -368 -929 -195 -201 -421 -327 -684 -381 -362 -73 -740 0 -1039 201 -40 27 -121 95 -180 151 -150 141 -257 218 -408 293 -144 72 -237 105 -382 137 -136 30 -474 33 -603 4 -182 -39 -396 -121 -438 -167 -33 -35 -30 -98 6 -132 38 -37 82 -35 174 7 105 48 216 81 337 101 137 23 388 16 507 -14 256 -64 436 -162 638 -348 167 -153 269 -228 401 -293 386 -191 804 -220 1213 -84 370 122 690 401 867 754 57 113 116 289 141 420 28 145 26 428 -4 577 -68 342 -237 638 -492 861 -277 243 -601 368 -975 377 -82 2 -166 1 -185 -1z' />
      <path d='M3329 4565 c-34 -18 -46 -50 -58 -160 -6 -49 -15 -91 -20 -93 -5 -2 -38 -21 -74 -42 -36 -22 -70 -40 -76 -40 -6 0 -46 16 -90 35 -93 41 -142 45 -174 13 -34 -34 -237 -392 -237 -417 0 -41 28 -77 104 -130 l76 -54 0 -96 0 -97 -76 -54 c-82 -59 -109 -97 -100 -141 7 -37 210 -389 237 -411 32 -27 80 -22 176 20 l89 39 48 -33 c27 -18 61 -38 77 -44 30 -13 34 -24 44 -130 14 -148 18 -150 305 -150 242 0 268 5 288 57 7 15 16 69 22 118 6 50 14 91 18 93 4 1 38 20 76 42 38 22 73 40 78 40 5 0 46 -16 89 -35 94 -41 138 -44 173 -12 40 37 236 387 236 423 0 41 -23 68 -109 129 l-71 50 0 95 1 95 75 55 c88 64 104 83 104 125 0 35 -196 386 -236 423 -35 31 -79 28 -173 -13 -92 -41 -94 -41 -129 -16 -15 10 -47 29 -72 41 -25 12 -46 23 -47 24 -1 1 -7 42 -13 91 -13 111 -25 142 -61 160 -40 21 -462 21 -500 0z m365 -217 c3 -18 8 -55 11 -82 11 -80 20 -92 119 -141 50 -24 113 -61 140 -80 59 -43 85 -44 171 -5 37 17 72 30 79 30 7 0 20 -15 29 -32 9 -18 32 -61 51 -94 l36 -61 -58 -41 c-101 -70 -96 -60 -98 -255 -1 -124 3 -178 12 -193 7 -12 42 -43 78 -69 l65 -48 -35 -61 c-19 -34 -42 -76 -51 -93 -9 -18 -22 -33 -28 -33 -7 0 -42 13 -78 30 -91 41 -116 39 -192 -15 -34 -25 -96 -60 -136 -79 -41 -19 -79 -43 -86 -53 -6 -11 -14 -46 -18 -79 -3 -32 -9 -71 -11 -86 l-6 -28 -109 0 c-80 0 -109 3 -109 13 -1 43 -21 161 -30 178 -6 11 -48 37 -93 58 -45 21 -106 57 -136 80 -67 50 -95 52 -186 11 -37 -17 -71 -30 -75 -30 -5 0 -25 28 -46 63 -20 34 -45 76 -55 93 l-19 31 53 36 c105 73 102 63 103 266 0 115 -3 183 -10 193 -6 8 -41 36 -77 64 l-66 50 48 84 c27 47 54 89 61 93 8 5 39 -3 81 -22 93 -42 120 -40 187 11 30 22 92 58 137 79 45 21 87 47 93 58 9 17 29 135 30 179 0 9 29 12 110 12 l109 0 5 -32z' />
      <path d='M3489 3917 c-103 -29 -181 -96 -228 -197 -37 -80 -37 -201 1 -282 34 -72 103 -142 176 -176 48 -23 71 -27 142 -27 99 0 168 26 239 90 117 106 143 308 56 444 -78 122 -247 187 -386 148z m157 -203 c47 -22 76 -67 81 -123 6 -63 -20 -109 -77 -138 -54 -28 -82 -28 -138 -3 -33 15 -47 29 -62 62 -25 55 -25 82 0 133 36 74 122 104 196 69z' />
      <path d='M458 2631 c-27 -24 -33 -36 -33 -71 0 -100 135 -136 181 -49 28 55 14 108 -36 134 -43 22 -76 18 -112 -14z' />
      <path d='M1289 2525 c-33 -18 -46 -51 -54 -135 -10 -110 -12 -115 -58 -138 -23 -12 -59 -31 -79 -43 l-36 -22 -85 36 c-94 41 -144 46 -176 20 -27 -23 -230 -375 -237 -412 -9 -43 14 -74 99 -139 l79 -58 0 -94 0 -93 -80 -58 c-83 -61 -109 -99 -98 -143 10 -39 215 -389 239 -408 33 -27 84 -21 176 21 l85 38 69 -43 c39 -24 74 -45 79 -46 5 -2 15 -50 22 -107 12 -97 14 -106 44 -132 l32 -29 226 0 c247 0 271 5 293 57 10 25 30 150 31 194 0 8 17 23 38 33 20 10 56 31 78 46 l42 28 87 -39 c101 -45 143 -49 179 -17 38 35 236 388 236 421 0 44 -14 62 -100 127 l-80 61 0 92 1 92 75 55 c88 64 104 83 104 125 0 36 -196 387 -236 423 -35 31 -75 28 -177 -15 -87 -36 -90 -36 -115 -19 -15 9 -50 29 -78 43 -57 29 -51 12 -70 178 -5 46 -13 66 -35 87 l-27 28 -234 0 c-182 -1 -239 -4 -259 -15z m365 -202 c2 -10 7 -49 11 -86 8 -80 33 -114 103 -141 24 -9 69 -33 99 -54 31 -20 68 -45 82 -54 37 -25 69 -22 146 12 37 17 72 30 78 30 7 0 19 -13 28 -28 9 -16 33 -58 53 -94 l36 -65 -59 -42 c-99 -69 -96 -62 -96 -263 1 -132 4 -180 14 -192 8 -9 42 -37 77 -63 l63 -46 -35 -61 c-19 -34 -42 -76 -51 -93 -9 -18 -21 -33 -26 -33 -5 0 -39 13 -77 30 -94 41 -123 39 -190 -12 -30 -22 -91 -58 -135 -78 -88 -41 -99 -55 -110 -136 -3 -27 -8 -64 -11 -81 l-5 -33 -109 0 c-81 0 -110 3 -110 13 -1 43 -21 161 -31 178 -6 11 -47 38 -92 59 -45 21 -106 56 -136 79 -67 51 -94 52 -190 9 -43 -19 -75 -28 -81 -22 -4 5 -31 48 -58 96 l-50 87 39 26 c22 14 57 41 78 60 l38 35 -2 177 c-1 98 -5 185 -9 194 -4 9 -36 37 -72 63 -35 25 -64 50 -64 56 0 15 100 180 109 180 5 0 41 -13 79 -30 94 -40 116 -39 182 12 30 22 92 58 137 79 45 21 87 47 93 58 9 17 29 135 30 179 0 9 29 12 109 12 96 0 110 -2 115 -17z' />
      <path d='M1443 1876 c-104 -34 -176 -98 -222 -196 -35 -75 -35 -187 -1 -275 71 -182 302 -265 482 -174 253 129 247 498 -10 625 -68 33 -181 42 -249 20z m185 -213 c87 -63 79 -197 -14 -250 -66 -38 -136 -24 -187 36 -57 69 -39 174 38 219 48 28 122 26 163 -5z' />
      <path d='M212 2284 c-26 -13 -41 -35 -71 -98 -225 -475 -176 -1061 126 -1514 71 -107 241 -284 352 -367 373 -280 863 -373 1321 -250 416 112 770 390 959 754 l42 82 30 -26 c55 -46 127 -27 155 41 16 38 17 34 -58 208 -53 121 -82 156 -132 156 -30 0 -289 -119 -308 -141 -27 -32 -23 -91 9 -129 24 -29 31 -31 74 -28 l48 3 -40 -75 c-59 -108 -121 -192 -219 -294 -222 -232 -491 -361 -825 -397 -241 -25 -525 26 -740 133 -248 124 -495 379 -608 628 -80 177 -115 322 -124 510 -9 219 24 398 113 607 41 96 44 108 34 139 -20 59 -83 85 -138 58z' />
    </g>
  </svg>
);
export default DevOpsIcon;
