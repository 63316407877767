import loundryapp from '../../assets/img/loundry-app.png'
import moniterapp from '../../assets/img/moniter-app.png'
import datingapp from '../../assets/img/dating-app.png'

export const products = [
  {
    id: 1,
    title: 'Laundry App',
    description: 'A convenient laundry app that lets users schedule pickups, track orders, and make payments for washing, drying, and ironing services—all from their phone',
    img: loundryapp,
    loading:'lazy'
  },
  {
    id: 2,
    title: 'Screen Moniter App',
    description: 'A screen monitoring and employee management app that tracks work hours, projects, leaves, and productivity data in real-time for HR and managers.',
    img: moniterapp,
    loading:'lazy'
  },
  {
    id: 3,
    title: 'Dating App',
    description: 'A dating app that connects users with personalized matches, real-time chat, and secure profile recommendations for meaningful connections',
    img: datingapp,
    loading:'lazy'
  },
];
