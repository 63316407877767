const gradients = {
  0: 'linear-gradient(0deg,rgb(216, 203, 128) 0%, #C79E69 100%)',
  1: 'linear-gradient(0deg, #D9B688 0%, #E8B0B5 100%)',
  2: 'linear-gradient(0deg, #FF7F74 0%, #CB93C1 100%)',
  3: 'linear-gradient(0deg, #E6B064 0%, #CC9999 100%)',
  4: 'linear-gradient(0deg,rgb(104, 192, 204) 0%,rgb(60, 169, 184) 100%)',
};

const textColors = {
  1: '#1B6572',
  0: '#46876D',
  2: '#64F7DA',
  3: '#ED4958',
  4: '#FFD700',
};

const getById = <T>(data: Record<number, T>, id: number): T => {
  const keys = Object.keys(data).length;
  return data[(id - 1) % keys];
};

export const gradientColors = gradients;
export const getGradientById = (id: number): string => getById(gradients, id);

export const gradientTextColors = textColors;
export const getGradientTextById = (id: number): string =>
  getById(textColors, id);

export const gradientTitleTextColors = gradients;
export const getGradientTitleTextById = (id: number): string =>
  getById(gradients, id);

export const gradientFaqBackgroundColors = gradients;
export const setBackgroundFaqGradientById = (id: number): string =>
  getById(gradients, id);

export const gradientFeaturesBackgroundColors = gradients;
export const getGradientFeaturesBackgroundById = (id: number): string =>
  getById(gradients, id);
