import React, { useState } from 'react';
import './JobApplicationForm.css';
import InputField from '../InputField/InputField';
import type {
  JobApplicationFormProps,
} from '../../types/JobApplicationForm';
import toast, { Toaster } from 'react-hot-toast';
import { Spinner } from '../AllComponents';
import { CareerDetails } from '../../utils/APIsService/APIsServices';
import { useFormik } from 'formik';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { jobApplicationFormValidationSchema } from '../../utils/ValidationSchema/jobApplicationFormValidationSchema';
import { handlePhoneInputValidation } from '../../utils/ValidationSchema/HandlePhoneChange';

const JobApplicationForm: React.FC<JobApplicationFormProps> = ({
  onClose,
  formData,
}) => {
 
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      designation: formData.position || '',
      skill: '',
      current_position: '',
      education: '',
      company_name: '',
      total_experience: '',
      relevant_experience: '',
      current_ctc: '',
      expected_ctc: '',
      notice_period: '',
      current_location: '',
      cover_letter: null,
      uploded_cv: null,
    },
    validationSchema: jobApplicationFormValidationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const submissionData = new FormData();
      Object.keys(values).forEach((key) => {
        const value = values[key as keyof typeof values];
        if (value !== null && value !== undefined) {
          submissionData.append(key, value);
        }
      });
      try {
        const response = await CareerDetails.submitApplication(submissionData);
        setLoading(false);
        toast.success('Application submitted successfully!');
        onClose();
      } catch (error) {
        console.error('Error submitting application:', error);
        toast.error('Failed to submit the application. Please try again.');
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <div className='popup-overlay'>
      <div className='popup-content'>
        <button className='close-button ' onClick={onClose}>
          x
        </button>
        <h2>Apply for Job</h2>
        <form className='p-4' onSubmit={formik.handleSubmit}>
          <InputField
            label='Designation'
            placeholder={formik.values.designation}
            name='designation'
            className='nunito form-control'
            readOnly
          />
          <div className='name-group'>
            <div className='form-group'>
              <InputField
                label={
                  <>
                    First Name <span style={{ color: 'red' }}>*</span>
                  </>
                }
                type='text'
                name='first_name'
                value={formik.values.first_name}
                onChange={(e) => {
                  const regex = /^[a-zA-Z\s]*$/;
                  if (regex.test(e.target.value)) {
                    formik.handleChange(e);
                  }
                }}
                onBlur={formik.handleBlur}
                className={`form-control ${
                  formik.touched.first_name && formik.errors.first_name
                    ? 'is-invalid'
                    : ''
                }`}
                title='Enter your name'
                required
              />
              {formik.errors.first_name && formik.touched.first_name && (
                <small className='text-danger'>
                  {formik.errors.first_name}
                </small>
              )}
            </div>
            <div className='form-group'>
              <InputField
                label={
                  <>
                    Last Name <span style={{ color: 'red' }}>*</span>
                  </>
                }
                type='text'
                name='last_name'
                value={formik.values.last_name}
                onChange={(e) => {
                  const regex = /^[a-zA-Z\s]*$/;
                  if (regex.test(e.target.value)) {
                    formik.handleChange(e);
                  }
                }}
                onBlur={formik.handleBlur}
                className={`form-control ${
                  formik.touched.last_name && formik.errors.last_name
                    ? 'is-invalid'
                    : ''
                }`}
                title='Enter your last name'
                required
              />
              {formik.errors.last_name && formik.touched.last_name && (
                <small className='text-danger'>{formik.errors.last_name}</small>
              )}
            </div>
            <div className='form-group'>
              <InputField
                label={
                  <>
                    Email <span style={{ color: 'red' }}>*</span>
                  </>
                }
                type='email'
                name='email'
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`form-control ${
                  formik.touched.email && formik.errors.email
                    ? 'is-invalid'
                    : ''
                }`}
                title='Enter your email'
                required
              />
              {formik.errors.email && formik.touched.email && (
                <small className='text-danger'>{formik.errors.email}</small>
              )}
            </div>
            <div className='form-group'>        
              <label htmlFor='phone' className='nunito'>
                Phone Number <span style={{ color: 'red' }}>*</span>
              </label>
              <PhoneInput
                international
                defaultCountry='IN'
                value={formik.values.phone}
                onChange={(value) =>
                  handlePhoneInputValidation(
                    value,
                    formik.setFieldValue,
                    formik.validateField
                  )
                }
                id='phone'
                name='phone'
                className={`form-control   ${
                  formik.touched.phone && formik.errors.phone
                    ? 'is-invalid'
                    : ''
                }`}
              />
              {formik.touched.phone && formik.errors.phone && (
                <small className='text-danger'>{formik.errors.phone}</small>
              )}
            </div>
            <div className='form-group'>
              <InputField
                label={
                  <>
                    Skill/Technologies(All){' '}
                    <span style={{ color: 'red' }}>*</span>
                  </>
                }
                name='skill'
                value={formik.values.skill}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className='form-control'
                title='Enter your skills'
                required
              />
              {formik.errors.skill && formik.touched.skill && (
                <small className='text-danger'>{formik.errors.skill}</small>
              )}
            </div>
            <div className='form-group'>
              <InputField
                label={
                  <>
                    Position <span style={{ color: 'red' }}>*</span>
                  </>
                }
                type='text'
                name='current_position'
                value={formik.values.current_position}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className='form-control'
                title='Enter your position'
                required
              />
              {formik.errors.current_position &&
                formik.touched.current_position && (
                  <small className='text-danger'>
                    {formik.errors.current_position}
                  </small>
                )}
            </div>
          </div>

          <div className='name-group'>
            <div className='form-group'>
              <InputField
                label={
                  <>
                    Education <span style={{ color: 'red' }}>*</span>
                  </>
                }
                type='text'
                name='education'
                value={formik.values.education}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className='form-control'
                title='Enter your education'
                required
              />
              {formik.errors.education && formik.touched.education && (
                <small className='text-danger'>{formik.errors.education}</small>
              )}
            </div>
            <div className='form-group'>
              <InputField
                label={
                  <>
                    Company Name <span style={{ color: 'red' }}>*</span>
                  </>
                }
                type='text'
                name='company_name'
                value={formik.values.company_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className='form-control'
                title='Enter your company name'
                required
              />
              {formik.errors.company_name && formik.touched.company_name && (
                <small className='text-danger'>
                  {formik.errors.company_name}
                </small>
              )}
            </div>
            <div className='form-group'>
              <InputField
                label={
                  <>
                    Total Experience <span style={{ color: 'red' }}>*</span>
                  </>
                }
                type='text'
                name='total_experience'
                placeholder='e.g (1 or 1.5 year)'
                value={formik.values.total_experience}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className='form-control'
                required
              />
              {formik.errors.total_experience &&
                formik.touched.total_experience && (
                  <small className='text-danger'>
                    {formik.errors.total_experience}
                  </small>
                )}
            </div>
            <div className='form-group'>
              <InputField
                label={
                  <>
                    Relevant Experience <span style={{ color: 'red' }}>*</span>
                  </>
                }
                type='text'
                name='relevant_experience'
                placeholder='e.g (1 or 1.5 year)'
                value={formik.values.relevant_experience}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className='form-control'
                required
              />
              {formik.errors.relevant_experience &&
                formik.touched.relevant_experience && (
                  <small className='text-danger'>
                    {formik.errors.relevant_experience}
                  </small>
                )}
            </div>
            <div className='form-group'>
              <InputField
                label={
                  <>
                    Current CTC <span style={{ color: 'red' }}>*</span>
                  </>
                }
                type='text'
                name='current_ctc'
                placeholder='e.g (4.5 lakhs)'
                value={formik.values.current_ctc}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className='form-control'
                required
              />
              {formik.errors.current_ctc && formik.touched.current_ctc && (
                <small className='text-danger'>
                  {formik.errors.current_ctc}
                </small>
              )}
            </div>
            <div className='form-group'>
              <InputField
                label={
                  <>
                    Expected CTC <span style={{ color: 'red' }}>*</span>
                  </>
                }
                type='text'
                name='expected_ctc'
                placeholder='e.g (4.5 lakhs)'
                value={formik.values.expected_ctc}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className='form-control'
                required
              />
              {formik.errors.expected_ctc && formik.touched.expected_ctc && (
                <small className='text-danger'>
                  {formik.errors.expected_ctc}
                </small>
              )}
            </div>
            <div className='form-group'>
              <InputField
                label={
                  <>
                    Notice Period <span style={{ color: 'red' }}>*</span>
                  </>
                }
                type='text'
                name='notice_period'
                value={formik.values.notice_period}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className='form-control'
                placeholder='e.g (3 months, 2 weeks, 15 days)'
                required
              />
              {formik.errors.notice_period && formik.touched.notice_period && (
                <small className='text-danger'>
                  {formik.errors.notice_period}
                </small>
              )}
            </div>
            <div className='form-group'>
              <InputField
                label={
                  <>
                    Current Location <span style={{ color: 'red' }}>*</span>
                  </>
                }
                type='text'
                name='current_location'
                value={formik.values.current_location}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className='form-control'
                title='Enter your current_location'
                required
              />
              {formik.errors.current_location &&
                formik.touched.current_location && (
                  <small className='text-danger'>
                    {formik.errors.current_location}
                  </small>
                )}
            </div>
            <div className='form-group'>
              <label htmlFor='cvFile'>
                Upload CV <span style={{ color: 'red' }}>*</span>
              </label>
              <InputField
                type='file'
                name='file'
                accept='application/pdf'
                onChange={(e) => {
                  const file = e.target.files?.[0] || null;
                  formik.setFieldValue('uploded_cv', file);
                }}
                className='form-control'
                title='Upload your CV'
                required
              />
              {formik.errors.uploded_cv && formik.touched.uploded_cv && (
                <small className='text-danger'>
                  {formik.errors.uploded_cv}
                </small>
              )}
            </div>

            <div className='form-group'>
              <label htmlFor='coverLetterFile'>Cover Letter</label>
              <InputField
                type='file'
                name='cover_letter'
                accept='application/pdf,image/jpeg,image/png'
                onChange={(e) => {
                  const file = e.target.files?.[0] || null;
                  formik.setFieldValue('cover_letter', file);
                }}
                className='form-control'
                title='Upload your cover letter'
                required
              />
              {formik.errors.cover_letter && formik.touched.cover_letter && (
                <small className='text-danger'>
                  {formik.errors.cover_letter}
                </small>
              )}
            </div>
          </div>
          <button
            type='submit'
            className='btn icon con-border-remove btn-shiny nunito btn-loader'
            disabled={loading}
          >
            {loading ? (
              <Spinner fixed={false} color='#fff' background='transparant' translate={false} />
            ) : (
              'Submit Application'
            )}
          </button>
        </form>
        <div className='toaster-div'>
          <Toaster position='top-center' reverseOrder={false} />
        </div>
      </div>
    </div>
  );
};

export default JobApplicationForm;
