import React from 'react';
import './Accordion.css';

const Accordion: React.FC<AccordionProps> = ({
  items,
  activeIndex,
  onAccordionClick,
}) => {
  return (
    <div className='accordion'>
      {items.map(({ id, title, content }, index) => (
        <div key={id} className='accordion-item border-0 mb-4'>
          <h2 className='accordion-header'>
            <button
              className={`accordion-button rounded-top ${
                activeIndex === index ? '' : 'collapsed'
              }`}
              onClick={() => onAccordionClick(index)}
              type='button'
              aria-expanded={activeIndex === index}
            >
              <span className='content-fonts'>{title}</span>
            </button>
          </h2>
          <div
            className={`accordion-body ${activeIndex === index ? 'show' : ''}`}
          >
            <div className='p-3'>
              <div dangerouslySetInnerHTML={{ __html: content }}></div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
