import React from 'react';
import { TechCard } from '../AllComponents';
import { FrontendTechProps } from '../../types/FrontendTechTypes';

const ServiceTech: React.FC<FrontendTechProps> = React.memo(
  ({ technologies }) => {
    const Technologies = technologies.map((tech) => tech);
    return (
      <>
        <div className='tech-grid'>
          {Technologies.map((tech, index) => (
            <TechCard key={index} icon={tech.full_image_url} name={tech.name} slug={tech.slug} />
          ))}
        </div>
      </>
    );
  }
);

export default ServiceTech;
