import React, { FC } from 'react';
import './TechnologyDetailsHeader.css';
import { TechnologyDetailsHeaderProps } from '../../types/TechnologyDetailsHeader';

const TechnologyDetailsHeader: FC<TechnologyDetailsHeaderProps> = ({
  title,
  description,
  imageSrc,
}) => {
  return (
    <section
      className='wow fadeIn overflow-hidden mt-5'
      style={{ visibility: 'visible', animation: 'fadeIn;' }}
    >
      <div className='container-xl'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-6 order-lg-1 pe-lg-5'>
              <div className='position-relative technology-top-image'>
                <img src={imageSrc} alt={title}  loading='lazy' className='img-fluid' />
              </div>
            </div>
            <div
              className='col-lg-6 order-lg-2'
              style={{
                height: '500px',
                overflowY: 'auto',
                padding: '10px',
              }}
            >
              <h1 className='highlight'>{title}</h1>
              <span
                dangerouslySetInnerHTML={{
                  __html: description || '',
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TechnologyDetailsHeader;
