export const skills = [
    {
      title: 'Theme',
      description:
        'Expertise in designing and implementing responsive themes using TailwindCSS, Bootstrap, and Material-UI.',
    },
    {
      title: 'Libraries',
      description:
        'Proficient in utilizing popular JavaScript libraries such as React, Lodash, and Axios to enhance application functionality.',
    },
    {
      title: 'Server',
      description:
        'Experience in deploying and managing applications on cloud platforms like AWS, Google Cloud, and Digital Ocean.',
    },
    {
      title: 'Framework',
      description:
        'Skilled in developing applications using frameworks like React, Node.js, and .NET Core for robust backend solutions.',
    },
    {
      title: 'Version Control',
      description:
        'Proficient in using Git for version control, including platforms like GitHub, Bitbucket, and GitLab.',
    },
    {
      title: 'UI Libraries',
      description:
        'Experience with UI libraries such as Ant Design, PrimeNG, and Chakra UI for building user-friendly interfaces.',
    },
    {
      title: 'Database',
      description:
        'Knowledgeable in working with databases like Firebase, MongoDB, and SQL for data management.',
    },
    {
      title: 'Deployment Process',
      description:
        'Familiar with CI/CD processes using tools like Jenkins and Docker for efficient deployment.',
    },
    {
      title: 'Project Management Tools',
      description:
        'Experience with project management tools like JIRA, Trello, and Redmine for effective team collaboration.',
    },
    {
      title: 'Payment Integration',
      description:
        'Skilled in integrating payment gateways such as Stripe, Braintree, and Razorpay into applications.',
    },
    {
      title: 'Communication Tools',
      description:
        'Proficient in using communication tools like Slack, Microsoft Teams, and Zoom for team collaboration.',
    },
    {
      title: 'Designer Tools',
      description:
        'Experience with design tools like Figma and InVision for creating and prototyping user interfaces.',
    },
    {
      title: 'Testing Frameworks',
      description:
        'Familiar with testing frameworks like Jest and Mocha for ensuring code quality and reliability.',
    },
    {
      title: 'Languages',
      description:
        'Proficient in programming languages such as JavaScript, TypeScript, and Python.',
    },
    {
      title: 'Utilities',
      description:
        'Experience with development utilities like VS Code and Postman for efficient coding and API testing.',
    },
  ];