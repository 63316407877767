import React from 'react';
import { Container, Row } from 'react-bootstrap';
import {
  Breadcrumb,
  InfoHeader,
  JobCard,
  Spinner,
  useFetch,
  UseMetaTags,
  DataNotFound,
  truncatedDesc,
} from '../../components/AllComponents';
import { CareersProps } from '../../types/CareersTypes';
import { Career, CareersApiResponse } from '../../types/APIs';
import { CareerService } from '../../utils/APIsService/APIsServices';
import { useLocation } from 'react-router-dom';

const Careers: React.FC<CareersProps> = () => {
  const location = useLocation();

  const {
    data: career,
    loading,
    error,
  } = useFetch<CareersApiResponse>(
    () => CareerService.getCareers(),
    location.pathname === '/career'
  );

  const jobData = career?.data || [];
  UseMetaTags({
    title: 'Careers - Join Mayora Infotech | IT Jobs in Ahmedabad',
    description:
      'Explore exciting career opportunities at Mayora Infotech. Join our team of IT professionals and contribute to innovative projects in web development, mobile app development, and more.',
    keywords:
      'careers, jobs in Ahmedabad, IT jobs, web development careers, mobile app development careers, Mayora Infotech',
    author: 'Mayora Infotech',
    language: 'English',
    ogtitle: 'Careers at Mayora Infotech',
    ogdescription:
      'Kickstart your career with Mayora Infotech. Discover our open positions and learn how you can make an impact in the IT industry.',
    ogImage: 'https://mayorainfotech.com/assets/careers-image.jpg',
    ogtype: 'website',
    ogurl: 'https://mayorainfotech.com/career/',
    canonical: 'https://mayorainfotech.com/career/',
  });

  return (
    <>
      <Breadcrumb title='Career' currentPage='Career'  currentPath='/career'/>
      <div className='container-fluid p-0'>
        <section className='py-5 mt-4'>
          <InfoHeader
            title='Empower Your Career with Mayora Infotech'
            description='At Mayora Infotech, we believe in fostering a culture of innovation, collaboration, and growth. Join our team and help us shape the future of technology while advancing your career in a dynamic environment.'
          />
          <Container className='mt-5'>
            <Row className='g-4 py-5 api-data-height'>
              {loading ? (
                <div className='col-12 text-center'>
                  <Spinner
                    background='transparent'
                    fixed={false}
                    translate={false}
                  />
                </div>
              ) : error ? (
                <div className='col-12 text-center'>
                  <DataNotFound />
                </div>
              ) : (
                jobData.map((career: Career) => {
                  return (
                    <JobCard
                      key={career.id}
                      id={career.id}
                      title={career.title}
                      location={career.location}
                      experience={career.experience}
                      vacancies={career.vacancy}
                      slug={career.slug}
                      description={
                        <div
                          dangerouslySetInnerHTML={{
                            __html: `${truncatedDesc(
                              career.job_description,
                              200
                            )}...`,
                          }}
                        />
                      }
                      routeType='regular'
                    />
                  );
                })
              )}
            </Row>
          </Container>
        </section>
      </div>
    </>
  );
};

export default Careers;
