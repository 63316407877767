import React from 'react';
import fallbackImage from '../../assets/img/blog-progress.png';
import { CardProps } from '../../types/BlogCard';
import '../../pages/Blog/Blog.css';

const BlogCard: React.FC<CardProps> = ({
  image,
  title,
  date,
  animationDelay = '0.1s',
  onClick,
}) => {
  const formatDate = (date: string) => {
    const [day, month, year] = date.split('/').map(Number);
    const formattedDate = new Date(year, month - 1, day);
    const options: Intl.DateTimeFormatOptions = {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    };
    return new Intl.DateTimeFormat('en-US', options).format(formattedDate);
  };

  return (
    <div className='col-md-6 col-lg-4 col-xl-3' style={{ cursor: 'pointer' }}>
      <div
        className={`blog-item wow fadeInUp`}
        data-wow-delay={animationDelay}
        onClick={onClick}
      >
        <div className='blog-img'>
          <img
            src={image || fallbackImage}
            height='300'
            width='100%'
            alt={title}
            loading='lazy'
            className='blogs-images'
          />
        </div>
        <div className='blog-content text-dark border p-4'>
          <div className='blog-info mb-1'>
            <h5 className='mb-4 blog-title'>{title}</h5>
            <p>{formatDate(date)}</p>
          </div>
          <div className='btn btn-light rounded-pill py-2 px-4 nunito'>
            Read More
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
