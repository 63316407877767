import React from 'react';
import './VideoSection.css';
import { VideoSectionprops } from '../../types/VideoSection';

const VideoSection: React.FC<VideoSectionprops> = ({
  videoSrc,
  title,
  description,
  listItems,
}) => {
  return (
    <div className='container'>
      <div className='row video-section align-items-center'>
        <div className='col-12 col-md-12 col-lg-6 mb-4 mb-md-0'>
          <div className='video position-relative'>
            <video src={videoSrc} className='img-fluid' muted autoPlay />
            <div className='video-overlay position-absolute w-100 h-100'></div>
          </div>
        </div>
        <div className='col-md-12 col-lg-6 mb-12'>
          <div className='txt-entry'>
            <h3>{title}</h3>
            <h4>{description}</h4>
            {listItems && (
              <ul>
                {listItems.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoSection;
