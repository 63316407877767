import React from 'react';
import './TechCard.css';
import { TechCardProps } from '../../types/TechCardTypes';
import { useNavigate } from 'react-router-dom';

const TechCard: React.FC<TechCardProps> = ({ icon, name, slug }) => {
  const navigate = useNavigate();
  const cardClassName = name
    .toLowerCase()
    .replace(/\s+/g, ' ')
    .replace('.', 'dot');

  const handleCardClick = () => {
    navigate(`/service/detail/${slug}`);
  };
  return (
    <div
      className={`tech-card wow fadeInUp ${cardClassName}`}
      data-wow-delay='0.1s'
      onClick={handleCardClick}
    >
      <div className='icon-container'>
        <div className='circle'></div>
        <img src={icon} className='tech-icon img-fluid' alt='icon' loading='lazy' />
      </div>
      <h3 className='content '>{name}</h3>
    </div>
  );
};

export default TechCard;
