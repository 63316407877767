import { FaqItem } from '../../types/FaqItem';

export const faqData: FaqItem[] = [
  {
    id: 1,
    title: "Why Choose Mayora Infotech for IT Services?",
    content:
      "Mayora Infotech is a trusted name in IT services, offering innovative and reliable solutions tailored to your business needs. From cloud computing to cybersecurity, software development to IT consultancy, we deliver services that drive growth and efficiency. Our commitment to excellence, advanced technologies, and dedicated support ensures your business stays ahead in a competitive market. Partner with Mayora Infotech for comprehensive IT solutions that empower your business to thrive in the digital era.",
  },
  {
    id: 2,
    title: "Are there any hidden charges?",
    content:
      "At Mayora Infotech, we value transparency in all our dealings. We strive to provide clear and detailed pricing for our IT services. While our focus is on cost-effective solutions, we recommend discussing specific project requirements with our sales or support team to ensure you have a complete understanding of all potential costs. This approach ensures you are fully informed and can avoid unexpected expenses, fostering a relationship built on trust and clarity.",
  },
  {
    id: 3,
    title: "What are the key challenges businesses face in IT management?",
    content:
      `<h5>Managing IT effectively is critical for modern businesses, but it comes with several challenges. Here are some key hurdles:</h5>
       <ul>
         <li><strong>Data Security:</strong> Protecting sensitive information against cyber threats is a top priority but requires constant vigilance and advanced tools.</li>
         <li><strong>Scalability:</strong> Ensuring IT infrastructure grows seamlessly with business needs can be complex and resource-intensive.</li>
         <li><strong>Technology Integration:</strong> Combining legacy systems with new technologies often leads to compatibility and functionality challenges.</li>
         <li><strong>Cost Management:</strong> Balancing IT budgets while maintaining quality and innovation is a constant struggle.</li>
         <li><strong>Skill Gaps:</strong> Finding and retaining skilled IT professionals is challenging in a rapidly evolving tech landscape.</li>
         <li><strong>Compliance:</strong> Adhering to regulations like GDPR and industry-specific standards requires significant attention.</li>
         <li><strong>Downtime Prevention:</strong> Minimizing disruptions and maintaining uptime is crucial for operational efficiency.</li>
         <li><strong>Data Management:</strong> Effectively managing and analyzing large volumes of data for insights requires advanced solutions.</li>
       </ul>`,
  },
];
