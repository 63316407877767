import React, { useEffect, useState } from 'react';
import {
  CustomButton,
  InfoHeader,
  ProductCarousel,
  ProductDetailAccordian,
  ProductsDetailsFaq,
  ProductsDetailsFeature,
  ProductsDetailsHeader,
  TestimonialCard,
  useFetch,
  UseMetaTags,
  useNavigateToPath,
  VideoSection,
} from '../../components/AllComponents';
import './productsdetails.css';
import moniterdeshboard from '../../assets/img/moniter deshboard.png';
import {
  getGradientById,
  getGradientFeaturesBackgroundById,
  getGradientTextById,
  getGradientTitleTextById,
  setBackgroundFaqGradientById,
} from '../../utils/GradientColors';
import { useParams } from 'react-router-dom';
import { ProductsDetailsApiResponse } from '../../types/APIs';
import {
  ProductsDetailsService,
} from '../../utils/APIsService/APIsServices';
import { NavLink } from 'react-router-dom';
import { tabs } from '../../utils/MockData/ProductDetailAccordian';
import Slider from 'react-slick';
import { Testimonialsettings } from '../../utils/SliderSettings';
import { ProductsfaqData } from '../../utils/MockData/ProductsDetailsFaq';
import { testimonials } from '../../utils/MockData/TestimonialSection';
import {
  aboutContent,
  featuresData,
} from '../../utils/MockData/ProductsDetails';

const ProductsDetails: React.FC = () => {
  UseMetaTags({
    title: 'Laundary App - Mayora Infotech | Innovative IT Solutions',
    description:
      'Learn more about laundary app, an innovative solution by Mayora Infotech. Discover its features, benefits, and how it can transform your business.',
    keywords:
      'laundary app, IT solutions, software features, technology services, Mayora Infotech, Ahmedabad',
    author: 'Mayora Infotech',
    language: 'English',
    ogtitle: 'Laundary App - Mayora Infotech',
    ogdescription:
      'Explore the details of laundary app and find out how it can help your business succeed. Join Mayora Infotech in embracing innovative technology.',
    ogImage: '%PUBLIC_URL%/product-image.jpg',
    ogtype: 'product',
    ogurl: 'https://mayorainfotech.com/products/Laundary-app/',
    canonical: 'https://mayorainfotech.com/products/Laundary-app/',
  });

  const { id } = useParams<{ id: string }>();
  const productId = id && !isNaN(Number(id)) ? parseInt(id, 10) : null;
  const { data, error, loading } = useFetch<ProductsDetailsApiResponse>(
    () => ProductsDetailsService.getProductDetails(productId),
    !!productId
  );

  const navigateToContactus = useNavigateToPath('/contact-us') ?? '#';
  const [backgroundGradient, setBackgroundGradient] = useState<string>('');
  const [backgroundTextGradient, setBackgroundTextGradient] =
    useState<string>('');
  const [backgroundTitleextGradient, setBackgroundTitleTextGradient] =
    useState<string>('');
  const [backgroundFaqGradient, setBackgroundFaqGradient] =
    useState<string>('');
  const [
    backgroundProductsDetailsFeturesGradient,
    setBackgroundProductsDetailsFeturesGradient,
  ] = useState<string>('');
  // const [tabsData, setTabsData] = useState([]);  this for slidder button data coming api
  // const [featuresData, setFeaturesData] = useState([]); this for features data coming api

  useEffect(() => {
    if (productId !== null) {
      const gradient = getGradientById(productId);
      setBackgroundGradient(gradient);
      const text = getGradientTextById(productId);
      setBackgroundTextGradient(text);
      const titletext = getGradientTitleTextById(productId);
      setBackgroundTitleTextGradient(titletext);
      const faqbackground = setBackgroundFaqGradientById(productId);
      setBackgroundFaqGradient(faqbackground);
      const ProductsDetailsFeturesbackground =
        getGradientFeaturesBackgroundById(productId);
      setBackgroundProductsDetailsFeturesGradient(
        ProductsDetailsFeturesbackground
      );
    }
  }, [productId]);

  const images = [moniterdeshboard, moniterdeshboard];

  return (
    <>
      <ProductsDetailsHeader
        backgroundGradient={backgroundGradient}
        imgSrc={moniterdeshboard}
        imgAlt='Monitor Dashboard'
        titleText='Provides Better'
        titleStyle={{
          color: backgroundTextGradient,
          fontWeight: 'bold',
          fontSize: '50px',
        }}
        typewriterWords={['Software', 'Quality', 'Support']}
        textColor={backgroundTitleextGradient}
        navigateTo='#'
        productsdescription='MonitorMate is an all-in-one employee activity management
                  solution that combines real-time screen monitoring,
                  productivity tracking, attendance, leave management, and
                  performance analytics to help organizations optimize employee
                  engagement, ensure accountability, and streamline HR
                  functions.'
      />
      <div
        id='products-about'
        className='products-about-section section pb-5 bg-white'
      >
        <div className='container'>
          <div className='section-head text-center mb-5'>
            <div className='row'>
              <div className='col-md-12'>
                <h2 className='products-about-heading'>
                  About <span className='appsland'>Monitor App</span>
                </h2>
                <p className='content-fonts'>{aboutContent.description}</p>
              </div>
            </div>
          </div>
          <VideoSection
            videoSrc={aboutContent.videoSrc}
            title={aboutContent.title}
            description={aboutContent.description}
            listItems={aboutContent.listItems}
          />
        </div>
      </div>
      <ProductCarousel
        productscarousalheading='Explore a wide range of products tailored to meet diverse
            requirements with cutting-edge technology.'
        images={images}
      />
      {/* <PriceCard /> */}
      <ProductsDetailsFeature
        backgroundGradient={backgroundProductsDetailsFeturesGradient}
        features={featuresData}
        productstitle='MonitorMate streamlines workforce management with real-time insights, tailored tracking modes, and productivity tools, ensuring accountability and seamless team coordination.'
        imageSrc='https://demo.themenio.com/appsland/images/software-screen-b.jpg'
      />
      <ProductDetailAccordian tabs={tabs} />
      <ProductsDetailsFaq
        backgroundGradient={backgroundFaqGradient}
        heading='Moniter App FAQ'
        subheading=''
        paragraph={
          <>
            We have compiled a list of FAQs for you. If your question is not
            here, please{' '}
            <NavLink className='text-secondary' to='/contact-us'>
              reach out
            </NavLink>{' '}
            to us.
          </>
        }
        items={ProductsfaqData}
      />
      <div className='container-fluid testimonial py-5'>
        <div
          className='container py-5 wow animate__zoomInRight'
          data-wow-delay='0.1s'
        >
          <div className='text-center mx-auto mb-5 section-heading'>
            <h4>Testimonial</h4>
            <InfoHeader
              title='Hear from Our Valued Clients'
              description="Our clients share their experiences and the positive impact Mayora's services have had on their businesses. From global brands to innovative startups, their stories highlight our commitment to excellence and innovation."
            />
          </div>
          <div className='slider-container'>
            <Slider {...Testimonialsettings}>
              {testimonials.map((testimonial, index) => (
                <div key={index} className='testimonial-slider'>
                  <TestimonialCard
                    imgSrc={testimonial.imgSrc}
                    name={testimonial.name}
                    description={testimonial.message}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <section
        id='home-contactus'
        className='wow fadeInUp'
        data-wow-delay='0.1s'
      >
        <div className='d-block d-md-flex home-contactus-container align-items-center px-5'>
          <div>
            <h2
              className='mb-0 animate__pulse wow contact-us-text'
              data-wow-delay='0.1s'
            >
              Innovation Starts with a Conversation. Let’s Discuss Your Ideas!
            </h2>
          </div>
        </div>
        <div>
          <CustomButton
            className='wow fadeInUp home-contactus-button'
            onClick={navigateToContactus}
            label='Contact Us'
          />
        </div>
      </section>
    </>
  );
};

export default ProductsDetails;
