import React from 'react';
import { InputFieldProps } from '../../types/InputFieldTypes';

const InputField: React.FC<InputFieldProps> = ({
  label,
  type = 'text',
  name,
  placeholder,
  value,
  onChange,
  required,
  readOnly,
  className,
  pattern,
  title,
  onBlur,
  maxLength,
}) => (
  <div >
    <label htmlFor={name} className='nunito'>
      {label}
    </label>
    <input
      type={type}
      className={className}
      id={name}
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      required={required}
      readOnly={readOnly}
      pattern={pattern}
      title={title}
      onBlur={onBlur}
      maxLength={maxLength}
    />
  </div>
);

export default InputField;
