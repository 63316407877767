import { FaqItem } from '../../types/FaqItem';

export const ProductsfaqData: FaqItem[] = [
  {
    id: 1,
    title: 'What is MonitorMate?',
    content:
      'MonitorMate is an all-in-one solution for managing employee activities, combining screen monitoring with essential HR tools to enhance productivity, accountability, and transparency while supporting employee engagement.',
  },
  {
    id: 2,
    title: 'How does MonitorMate help organizations improve performance tracking?',
    content:
      'MonitorMate provides real-time monitoring and actionable insights, helping managers make informed decisions and improve individual and team performance. It also addresses challenges in remote and hybrid work models.',
  },
  {
    id: 3,
    title: 'What does the Dashboard feature offer?',
    content: `The Dashboard serves as a centralized hub providing detailed analytics, progress graphs, and performance trends for individual employees.`,
  }
];
