import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Footer, Navbar } from '../../components/AllComponents';

const Layout: React.FC = () => {
  const location = useLocation();
  const isNopage =
    location.pathname === '*' || location.pathname === '/page not found';

  return (
    <div>
      {!isNopage && <Navbar />}
      <main>
        <Outlet />
      </main>
      {!isNopage && <Footer />}
    </div>
  );
};

export default Layout;
