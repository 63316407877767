import React from 'react';
import './FeatureSection.css';
import { FeatureSectionProps } from '../../types/FeatureSectionTypes';
import { Spinner } from '../AllComponents';

const FeatureSection: React.FC<FeatureSectionProps> = ({
  title,
  content,
  features,
  imageSrc,
  imageOrder,
  contentOrder,
  loading,
}) => {
  const titleWords = title.split(' ');
  const firstPart = titleWords[0];
  const secondWord = titleWords[1] || '';
  const remainingTitle = titleWords.slice(2).join(' ');
  return (
    <section className='about-area ptb-100 wow fadeInUp' data-wow-delay='0.1s' >
      <div className='container'>
        <div className='row align-items-center'>
          <div
            className={`col-lg-6 col-md-12 order-lg-${imageOrder}`}
            data-wow-delay='0.3s'
          >
            <div className='about-image'>
              {loading ? (
                <Spinner
                  fixed={false}
                  background='transparent'
                  translate={false}
                />
              ) : (
                <img
                  src={imageSrc}
                  alt={title}
                  className='feature-images'
                  loading='lazy'
                  height='300px'
                  width='300px'
                />
              )}
            </div>
          </div>
          <div
            className={`col-lg-6 col-md-12 order-lg-${contentOrder}`}
            data-wow-delay='0.3s'
          >
            <div
              className={`about-content align-items-center text-left`}
            >
              <span className='sub-title  nunito'>Our Features</span>
              <h2 className='feature-title'>
                {firstPart} <span className='highlight'>{secondWord}</span>{' '}
                {remainingTitle}
              </h2>
              <h6 className='nunito'>{content}</h6>
              <ul className='features-list'>
                {features.map((feature, index) => (
                  <li
                    key={index}
                    className='wow fadeInUp nunito'
                    data-wow-delay={`${0.1 * index}s`}
                  >
                    <span>
                      <i className='fas fa-check'></i> {feature}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureSection;
