import * as React from 'react';
import { AgileIconProps } from '../../types/icon';
const VectorIcon = ({
  className,
  ariaLabel,
  width,
  height,
}: AgileIconProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 512.000000 512.000000'
    preserveAspectRatio='xMidYMid meet'
    className={className}
    aria-label={ariaLabel}
    width={width}
    height={height}
  >
    <g
      transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
      fill='#C0E3FB'
      stroke='none'
    >
      <path d='M78 5099 c-23 -12 -46 -35 -58 -59 -19 -37 -20 -58 -20 -370 0 -312 1 -333 20 -370 13 -26 34 -47 60 -60 37 -19 58 -20 370 -20 312 0 333 1 370 20 60 31 80 78 80 190 l0 90 308 -1 c292 0 306 -1 282 -17 -415 -285 -699 -690 -820 -1168 -16 -62 -34 -158 -40 -211 -6 -54 -13 -107 -16 -118 -2 -13 -27 -32 -69 -54 -160 -82 -257 -276 -227 -455 32 -198 179 -345 376 -378 186 -31 387 75 467 246 62 133 61 272 -2 398 -38 75 -127 166 -193 197 l-49 22 7 71 c41 442 317 902 706 1176 99 70 293 166 413 206 59 20 109 36 110 36 2 0 18 -22 36 -50 77 -119 227 -200 371 -200 144 0 294 81 371 200 18 28 34 50 36 50 1 0 51 -16 110 -36 120 -40 314 -136 413 -206 389 -274 665 -734 706 -1176 l7 -71 -49 -22 c-66 -31 -155 -122 -193 -197 -137 -271 30 -594 333 -644 292 -48 558 221 508 514 -24 136 -111 260 -227 319 -42 22 -67 41 -69 54 -3 11 -10 64 -16 118 -29 247 -131 535 -271 764 -138 225 -354 452 -569 596 l-55 37 313 0 312 0 0 -90 c0 -112 20 -159 80 -190 37 -19 58 -20 370 -20 312 0 333 1 370 20 26 13 47 34 60 60 19 37 20 58 20 370 0 312 -1 333 -20 370 -13 26 -34 47 -60 60 -37 19 -58 20 -370 20 -312 0 -333 -1 -370 -20 -60 -31 -80 -78 -80 -190 l0 -90 -617 0 -618 0 -34 65 c-60 116 -183 203 -321 227 -182 31 -378 -65 -461 -227 l-34 -65 -617 0 -618 0 0 90 c0 112 -20 159 -80 190 -37 19 -58 20 -372 20 -312 -1 -335 -2 -370 -21z m522 -429 l0 -150 -150 0 -150 0 0 150 0 150 150 0 150 0 0 -150z m2030 130 c45 -23 80 -80 80 -130 0 -50 -35 -107 -80 -130 -21 -11 -53 -20 -70 -20 -50 0 -107 35 -130 80 -25 50 -25 90 0 140 37 73 124 99 200 60z m2190 -130 l0 -150 -150 0 -150 0 0 150 0 150 150 0 150 0 0 -150z m-3990 -1980 c45 -23 80 -80 80 -130 0 -76 -74 -150 -150 -150 -76 0 -150 74 -150 150 0 48 35 107 78 129 48 26 93 26 142 1z m3600 0 c45 -23 80 -80 80 -130 0 -17 -9 -49 -20 -70 -23 -45 -80 -80 -130 -80 -50 0 -107 35 -130 80 -25 50 -25 90 0 140 37 73 124 99 200 60z' />
      <path d='M2491 3892 c-28 -14 -138 -173 -641 -927 -334 -501 -615 -925 -624 -942 -33 -64 -15 -122 66 -210 193 -210 333 -445 418 -700 37 -113 80 -296 80 -343 0 -14 -25 -49 -64 -91 -109 -115 -181 -259 -205 -409 -23 -142 -4 -213 69 -250 38 -20 57 -20 970 -20 913 0 932 0 970 20 73 37 92 108 69 250 -24 150 -96 294 -205 409 -39 42 -64 77 -64 91 0 47 43 230 80 343 85 255 225 490 418 700 81 88 99 146 66 209 -9 18 -290 442 -624 942 -532 799 -611 913 -644 929 -45 21 -91 21 -135 -1z m-81 -1069 l0 -438 -65 -34 c-160 -82 -257 -277 -227 -455 32 -198 179 -345 376 -378 292 -48 558 221 508 514 -24 136 -111 260 -227 319 l-65 34 0 440 0 440 429 -644 430 -644 -59 -71 c-206 -250 -371 -588 -443 -906 l-24 -105 -49 5 c-27 3 -222 5 -434 5 -212 0 -407 -2 -434 -5 l-49 -5 -24 105 c-72 318 -237 656 -443 906 l-59 71 427 641 c235 353 428 642 430 642 1 0 2 -197 2 -437z m220 -733 c45 -23 80 -80 80 -130 0 -76 -74 -150 -150 -150 -50 0 -107 35 -130 80 -25 50 -25 90 0 140 37 73 124 99 200 60z m335 -1495 c83 -22 154 -66 217 -134 45 -48 98 -133 98 -156 0 -3 -324 -5 -720 -5 -396 0 -720 2 -720 5 0 23 53 108 98 156 61 66 134 112 213 133 81 22 730 22 814 1z' />
    </g>
  </svg>
);
export default VectorIcon;
