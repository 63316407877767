import * as React from 'react';
import { IconProps } from '../../types/icon';
const IotDevelopmentIcon = ({ className }: IconProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='512.000000pt'
    height='512.000000pt'
    viewBox='0 0 512.000000 512.000000'
    preserveAspectRatio='xMidYMid meet'
    className={className}
  >
    <g
      transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
      fill='#7de221'
      stroke='none'
    >
      <path d='M450 4804 c-216 -46 -401 -235 -439 -448 -8 -41 -11 -608 -11 -1800 0 -1455 3 -1752 14 -1807 36 -166 155 -317 308 -388 46 -22 110 -44 142 -50 84 -15 4108 -15 4192 0 216 39 404 222 450 438 11 55 14 352 14 1807 0 1192 -3 1759 -11 1800 -29 160 -153 326 -300 398 -143 70 6 66 -2254 65 -1710 0 -2051 -3 -2105 -15z m4170 -164 c130 -25 236 -107 293 -228 l32 -67 3 -357 3 -358 -2391 0 -2391 0 3 358 3 357 32 67 c55 117 163 203 285 227 63 13 4063 13 4128 1z m330 -2498 c0 -1455 3 -1381 -63 -1485 -44 -68 -96 -113 -177 -151 l-65 -31 -2085 0 -2085 0 -65 31 c-81 38 -133 83 -177 151 -66 104 -63 30 -63 1485 l0 1318 2390 0 2390 0 0 -1318z' />
      <path d='M571 4399 c-168 -32 -261 -218 -187 -374 79 -167 300 -203 429 -70 42 43 77 128 77 185 0 65 -35 143 -86 190 -69 64 -144 86 -233 69z m122 -200 c29 -31 34 -63 16 -103 -15 -33 -71 -59 -108 -51 -85 19 -100 138 -22 177 44 22 78 15 114 -23z' />
      <path d='M1340 4379 c-99 -44 -160 -138 -160 -244 1 -236 284 -352 451 -185 167 167 50 450 -186 450 -39 0 -76 -8 -105 -21z m174 -180 c36 -43 35 -91 -3 -130 -22 -21 -39 -29 -66 -29 -83 0 -127 105 -69 164 40 39 103 37 138 -5z' />
      <path d='M2171 4383 c-26 -9 -65 -36 -91 -62 -167 -167 -51 -450 185 -451 148 0 265 117 265 265 0 185 -183 310 -359 248z m160 -182 c39 -40 41 -97 3 -135 -59 -58 -164 -14 -164 69 0 51 44 95 95 95 27 0 44 -8 66 -29z' />
      <path d='M2655 2948 c-24 -22 -37 -76 -201 -855 -159 -755 -174 -835 -162 -860 28 -58 102 -65 139 -12 12 15 77 308 188 835 94 446 171 818 171 827 0 29 -22 65 -47 76 -36 16 -59 13 -88 -11z' />
      <path d='M1970 2613 c-55 -39 -561 -476 -570 -493 -19 -35 -11 -68 23 -102 54 -54 537 -462 564 -476 30 -16 88 0 103 28 18 34 11 78 -17 109 -16 16 -125 111 -243 211 -118 101 -216 186 -218 190 -1 4 99 94 223 199 124 106 235 202 245 214 28 30 26 82 -5 112 -29 29 -70 32 -105 8z' />
      <path d='M3045 2605 c-31 -30 -33 -82 -5 -112 10 -12 121 -108 245 -214 124 -105 224 -195 223 -199 -2 -4 -100 -89 -218 -190 -118 -100 -227 -195 -242 -211 -29 -31 -36 -75 -18 -109 15 -28 73 -44 103 -28 27 14 510 422 564 476 34 34 42 67 23 102 -9 17 -515 454 -570 493 -35 24 -76 21 -105 -8z' />
    </g>
  </svg>
);
export default IotDevelopmentIcon;
