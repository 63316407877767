import React, { useState, useCallback } from 'react';
import {
  Breadcrumb,
  TechnologySection,
  AgileDevelopmentSection,
  UseMetaTags,
  DevelopmentServices,
  InfoHeader,
} from '../../components/AllComponents';

const Service: React.FC = React.memo(() => {
  UseMetaTags({
    title: 'Our Services - Mayora Infotech | Leading IT Solutions in Ahmedabad',
    description:
      'Discover a range of IT services offered by Mayora Infotech in Ahmedabad...',
    keywords:
      'IT services, web development, mobile app development, software solutions, digital marketing, Ahmedabad',
    author: 'Mayora Infotech',
    language: 'English',
    ogtitle: 'Our Services - Mayora Infotech',
    ogdescription: 'Explore the IT services provided by Mayora Infotech...',
    ogImage: '%PUBLIC_URL%/favicon.ico',
    ogtype: 'website',
    ogurl: 'https://mayorainfotech.com/service/',
    canonical: 'https://mayorainfotech.com/service/',
  });

  const [activeTab, setActiveTab] = useState<string>('');
  const handleTabClick = useCallback((tab: string) => {
    setActiveTab(tab);
  }, []);

  return (
    <>
      <Breadcrumb
        title='Services'
        currentPage='Services'
        currentPath='/services'
      />
      <section className='mt-5'>
        <InfoHeader
          title='Our Software Development Services'
          description='Mayora Infotech provides a comprehensive range of services, including IoT-based development, WebRTC solutions, and customized mobile and web application development. Each service is meticulously tailored to meet the unique needs and goals of our clients, ensuring innovative, high-quality, and efficient solutions.'
        />
        <DevelopmentServices />
        <TechnologySection activeTab={activeTab} onTabClick={handleTabClick} />
        <AgileDevelopmentSection />
      </section>
    </>
  );
});

export default Service;
