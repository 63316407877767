import React from 'react';
import './NoPage.css';
import nopage from '../../assets/img/404-page.gif';
import { useNavigateToPath } from '../../components/AllComponents';

const NoPage: React.FC = () => {
  const navigateToHome = useNavigateToPath('/') ?? '#';

  return (
    <div className='nopage-container' onClick={navigateToHome}>
        <img src={nopage} className='image-fluid nopage' loading='lazy' />
    </div>
  );
};

export default NoPage;
