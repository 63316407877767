import * as React from 'react';
import { IconProps } from '../../types/icon';
const WebDevelopmentIcon = ({ className }: IconProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='512.000000pt'
    height='512.000000pt'
    viewBox='0 0 512.000000 512.000000'
    preserveAspectRatio='xMidYMid meet'
    className={className}
  >
    <g
      transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
      fill='#DAA520'
      stroke='none'
    >
      <path d='M678 5099 c-23 -12 -46 -35 -58 -59 -18 -36 -20 -58 -20 -280 l0 -240 -240 0 c-222 0 -244 -2 -280 -20 -26 -13 -47 -34 -60 -60 -20 -39 -20 -55 -20 -1720 0 -1665 0 -1681 20 -1720 13 -26 34 -47 60 -60 38 -20 57 -20 885 -20 l845 0 0 -310 0 -310 -240 0 c-222 0 -244 -2 -280 -20 -45 -23 -80 -80 -80 -130 0 -50 35 -107 80 -130 39 -20 56 -20 1270 -20 1214 0 1231 0 1270 20 45 23 80 80 80 130 0 50 -35 107 -80 130 -36 18 -58 20 -280 20 l-240 0 0 310 0 310 845 0 c828 0 847 0 885 20 26 13 47 34 60 60 20 39 20 55 20 1720 0 1665 0 1681 -20 1720 -13 26 -34 47 -60 60 -36 18 -58 20 -280 20 l-240 0 0 240 c0 222 -2 244 -20 280 -13 26 -34 47 -60 60 -39 20 -54 20 -1882 20 -1815 -1 -1844 -1 -1880 -21z m3542 -729 l0 -450 -1660 0 -1660 0 0 450 0 450 1660 0 1660 0 0 -450z m-3620 -1440 c0 -1274 0 -1291 20 -1330 13 -26 34 -47 60 -60 39 -20 55 -20 1880 -20 1825 0 1841 0 1880 20 26 13 47 34 60 60 20 39 20 56 20 1330 l0 1290 150 0 150 0 0 -1500 0 -1500 -2260 0 -2260 0 0 1500 0 1500 150 0 150 0 0 -1290z m3620 -210 l0 -900 -1660 0 -1660 0 0 900 0 900 1660 0 1660 0 0 -900z m-1210 -2110 l0 -310 -450 0 -450 0 0 310 0 310 450 0 450 0 0 -310z' />
      <path d='M1288 4499 c-43 -22 -78 -81 -78 -129 0 -76 74 -150 150 -150 76 0 150 74 150 150 0 50 -35 107 -80 130 -49 25 -94 25 -142 -1z' />
      <path d='M1888 4499 c-43 -22 -78 -81 -78 -129 0 -50 35 -107 80 -130 21 -11 53 -20 70 -20 76 0 150 74 150 150 0 50 -35 107 -80 130 -49 25 -94 25 -142 -1z' />
      <path d='M2488 4499 c-43 -22 -78 -81 -78 -129 0 -50 35 -107 80 -130 21 -11 53 -20 70 -20 76 0 150 74 150 150 0 50 -35 107 -80 130 -49 25 -94 25 -142 -1z' />
      <path d='M1288 3299 c-43 -22 -78 -81 -78 -129 0 -50 35 -107 80 -130 38 -19 58 -20 520 -20 462 0 482 1 520 20 45 23 80 80 80 130 0 50 -35 107 -80 130 -38 19 -57 20 -522 20 -461 -1 -485 -2 -520 -21z' />
      <path d='M2788 3299 c-42 -21 -78 -81 -78 -126 0 -31 296 -927 324 -980 19 -38 81 -73 126 -73 50 0 89 26 198 133 l102 102 103 -102 c56 -55 116 -108 134 -117 122 -61 258 75 197 197 -9 18 -62 78 -117 135 l-102 102 102 103 c107 108 133 147 133 197 0 39 -32 103 -62 122 -36 23 -959 328 -993 328 -17 -1 -47 -10 -67 -21z m515 -435 l177 -59 -127 -127 -128 -128 -58 172 c-31 95 -60 182 -63 192 -5 13 -2 18 7 14 8 -3 94 -32 192 -64z' />
      <path d='M1288 2699 c-43 -22 -78 -81 -78 -129 0 -50 35 -107 80 -130 38 -19 58 -20 520 -20 462 0 482 1 520 20 45 23 80 80 80 130 0 50 -35 107 -80 130 -38 19 -57 20 -522 20 -461 -1 -485 -2 -520 -21z' />
    </g>
  </svg>
);
export default WebDevelopmentIcon;
