import React from 'react';
import './TechnologyAboutServicesCard.css';
import { TechnologyCardprops } from '../../types/TechnologyAboutServices';

const TechnologyAboutServicesCard: React.FC<TechnologyCardprops> = ({
  technologycard,
  servicecardheading,
  servicecardheadingsummary,
}) => {
  return (
    <div className='container-fluid service py-4'>
      <div className='container pt-3'>
        <div className='row g-4 justify-content-center work-item'>
          <div className='col-12 text-center'>
            <h2 className='section-title'>
              Result-Driven{' '}
              <span className='highlight'>{servicecardheading} Development Services</span>
            </h2>
            <h5
              className='nunito font-sm-text mt-3'
            >Saeculum Solutions excels in {servicecardheadingsummary} development solutions for diverse applications, including custom solutions, SPAs, PWAs, and social media networking. We craft responsive, scalable, and efficient {servicecardheadingsummary} applications that drive business growth.</h5>
          </div>
          {technologycard.map(
            ({ id, name, descraption, backgroundcolor }, index) => (
              <div
                className={`col-md-6 col-lg-4 mb-4 d-flex align-items-stretch wow fadeInUp`}
                data-wow-delay={`${0.2 * index}s`}
                key={id}
              >
                <div
                  className='card technology-expertise-card border-0 rounded h-100'
                  style={{ backgroundColor: backgroundcolor }}
                >
                  <div className='card-body'>
                    <ul className='list-unstyled d-flex justify-content-between mb-0 mt-2'></ul>
                    <h4 className='card-title text-start mb-3'>{name}</h4>
                    <span className='separator'></span>
                    <span className='card-text content-fonts text-start'>
                      {descraption}
                    </span>
                  </div>
                </div>
              </div>
            )
          )}

        </div>
      </div>
    </div>
  );
};

export default TechnologyAboutServicesCard;
