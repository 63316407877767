import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './OurFeatures.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { featureData, featureTabs } from '../../utils/MockData/OurFeatures';
import {
  AchivementsCard,
  Breadcrumb,
  FeatureSection,
  InfoHeader,
  UseMetaTags,
} from '../../components/AllComponents';

const OurFeatures: React.FC = () => {
  const location = useLocation();
  useEffect(() => {}, [location]);
  const [activeTab, setActiveTab] = useState<number>(1);

  const [previousTab, setPreviousTab] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const handleTabClick = (index: number) => {
    setPreviousTab(activeTab);
    setActiveTab(index);
  };

  useEffect(() => {
    if (previousTab !== null && activeTab !== previousTab) {
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  }, [previousTab, previousTab]);

  UseMetaTags({
    title: 'Features - Mayora Infotech | Innovative IT Solutions in Ahmedabad',
    description:
      "Explore the unique features of Mayora Infotech's services, including cutting-edge technology solutions, user-friendly interfaces, and tailored IT services in Ahmedabad.",
    keywords:
      'IT features, innovative solutions, technology services, user-friendly interfaces, Ahmedabad',
    author: 'Mayora Infotech',
    language: 'English',
    ogtitle: "Features of Mayora Infotech's Services",
    ogdescription:
      "Learn about the standout features of Mayora Infotech's IT solutions, designed to empower businesses in Ahmedabad with technology-driven success.",
    ogImage: '%PUBLIC_URL%/favicon.ico',
    ogtype: 'website',
    ogurl: 'https://mayorainfotech.com/features/',
    canonical: 'https://mayorainfotech.com/features/',
  });

  const activeFeature = featureData.find((feature) => feature.id === activeTab);

  const getAnimationClass = (id: number) => {
    return id % 1 === 0 ? 'animate__zoomIn' : 'animate__heartBeat';
  };

  return (
    <>
      <Breadcrumb title='Features' currentPage='Features' currentPath='/features' />
      <section className='feature-section'>
        <div className='container mt-5'>
          <InfoHeader
            title='Empowering You with Features Designed for Success'
            description='Mayora Infotech delivers features that combine high performance
              with ease of use, such as integrated workflows, intuitive
              dashboards, and powerful reporting tools, to streamline your
              business processes.'
          />
          <div className='features-list-tab container'>
            <ul className='nav nav-tabs justify-content-center gap-3 py-3 px-3'>
              {featureTabs.map((tab, index) => (
                <li key={tab.id} className='nav-item '>
                  <button
                    className={`nav-link wow fadeInUp ${
                      activeTab === tab.id ? 'active' : ''
                    }`}
                    data-wow-delay={`${0.1 * index}s`}
                    onClick={() => handleTabClick(tab.id)}
                  >
                    <div className={`tab-icon ${tab.bgClass}`}>
                      <FontAwesomeIcon
                        icon={tab.icon}
                        className='tab-icon-item'
                      />
                      <span className='tab-texts content-fonts'>
                        {tab.label}
                      </span>
                    </div>
                  </button>
                </li>
              ))}
            </ul>
          </div>
          {activeFeature && (
            <FeatureSection
              activeTab={activeFeature.id}
              title={activeFeature.title}
              content={activeFeature.content}
              features={activeFeature.features}
              imageSrc={activeFeature.imageSrc}
              imageOrder={activeFeature.id % 2 === 0 ? 1 : 2}
              contentOrder={activeFeature.id % 2 === 0 ? 2 : 1}
              animationClass={getAnimationClass(activeFeature.id)}
              loading={loading}
            />
          )}
        </div>
        <div className='container'>
          <div className='d-flex justify-content-center flex-wrap'>
            <div className='col-12 col-sm-6 col-md-4 col-lg-3 mb-4'>
              <AchivementsCard
                value={45}
                label='Projects'
                highlightColor='rgba(0, 123, 255, 1)'
              />
            </div>
            <div className='col-12 col-sm-6 col-md-4 col-lg-3 mb-4'>
              <AchivementsCard
                value={25}
                label='Clients'
                highlightColor='rgba(0, 123, 255, 1)'
              />
            </div>
            <div className='col-12 col-sm-6 col-md-4 col-lg-3 mb-4'>
              <AchivementsCard
                value={25}
                label='Strength'
                highlightColor='rgba(0, 123, 255, 1)'
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurFeatures;
