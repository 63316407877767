export const features = [
    {
      title: 'Component-Based Architecture',
      content:
        'Build encapsulated components that manage their state and compose them to create complex UIs.',
    },
    {
      title: 'Declarative UI',
      content:
        'React’s declarative nature makes code predictable and easier to debug.',
    },
    {
      title: 'Virtual DOM',
      content:
        'Updates are optimized using a virtual DOM, making the rendering process faster.',
    },
    {
      title: 'Rich Ecosystem',
      content:
        'The React ecosystem includes tools like Redux for state management, React Router for navigation, and more.',
    },
  ];

 export  const technologycard = [
    {
      title: 'ReactJS Web Development',
      backgroundcolor: '#F1F8FF',
      description:
        'At Mayora Infotech, we specialize in ReactJS-powered web development to build dynamic, high-performance websites and web applications. Leveraging React’s component-based architecture and virtual DOM, we deliver scalable, responsive, and interactive solutions tailored to your business goals.',
    },
    {
      title: 'React Native App Development',
      backgroundcolor: '#F1F8FF',
      description:
        'Enhance your mobile presence with React Native, a cutting-edge framework for building cross-platform mobile apps with a single codebase. Our experts create fast, feature-rich, and visually appealing apps for iOS and Android, delivering exceptional user experiences that align with your brand.',
    },
    {
      title: 'ReactJS for IoT Applications',
      backgroundcolor: '#F1F8FF',
      description:
        'Revolutionize IoT development with ReactJS. From intuitive dashboards to real-time monitoring interfaces, our ReactJS solutions empower businesses to seamlessly interact with connected devices, providing secure, scalable, and efficient IoT applications that drive innovation and optimization.',
    },
    {
      title: 'ReactJS for E-commerce Solutions',
      backgroundcolor: '#F1F8FF',
      description:
        'Transform your online store with ReactJS. We craft user-friendly, feature-rich e-commerce platforms that deliver seamless shopping experiences. From dynamic product catalogs to secure payment integration, our ReactJS solutions ensure scalability and customer satisfaction.',
    },
    {
      title: 'Custom ReactJS Web Applications',
      backgroundcolor: '#F1F8FF',
      description:
        'Tailored to your unique requirements, our custom ReactJS web applications are designed to streamline business processes and enhance user engagement. Our solutions focus on functionality, responsiveness, and performance to drive measurable results.',
    },
    {
      // image: migrationAndIntegration,
      title: 'ReactJS Migration & Integration Services',
      backgroundcolor: '#F1F8FF',
      description:
        'Seamlessly migrate your existing applications to ReactJS or integrate ReactJS with your current tech stack. Our team ensures a smooth transition with minimal disruption, enabling you to leverage the latest React features for enhanced efficiency and innovation.',
    },
  ];

 export const developmentprocess = [
    {
      title: 'Requirement Analysis',
      points: [
        'Understanding business goals',
        'Defining scope and features',
        'Planning project timelines',
      ],
    },
    {
      title: 'UI/UX Design',
      points: [
        'Wireframes and prototypes',
        'Responsive and user-friendly design',
        'Feedback and iteration',
      ],
    },
    {
      title: 'Development',
      points: [
        'Component-based architecture',
        'Integration with APIs',
        'Optimized performance',
      ],
    },
    {
      title: 'Testing',
      points: [
        'Unit and integration testing',
        'Bug fixing and debugging',
        'Cross-browser compatibility',
      ],
    },
    {
      title: 'Deployment',
      points: [
        'Deployment to servers',
        'Continuous integration setup',
        'Performance monitoring',
      ],
    },
    {
      title: 'Maintenance',
      points: ['Regular updates', 'Feature enhancements', 'Ongoing support'],
    },
  ];