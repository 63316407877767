import React from 'react';
import './ProductsDetailsHeader.css';
import { Typewriter } from 'react-simple-typewriter';
import cardImage from '../../assets/img/carb.png';
import { Typeprops } from '../../types/Typeprops';
import Particle from '../Partical/Partical';

const ProductsDetailsHeader: React.FC<Typeprops> = ({
  backgroundGradient,
  imgSrc,
  imgAlt = 'header-image',
  titleText = 'Provides Better',
  textColor,
  fontWeight = 'bold',
  titleStyle = {
    color: '',
    fontWeight: 'bold',
  },
  typewriterWords = ['Software', 'Quality', 'Support'],
  typewriterConfig = {
    loop: true,
    cursor: true,
    cursorStyle: '|',
    typeSpeed: 100,
    deleteSpeed: 150,
    delaySpeed: 1000,
  },
  productsdescription,
}) => {
  return (
    <div
      id='home'
      className='d-flex justify-content-center header-curbed align-items-center'
      style={{ background: backgroundGradient }}
    >
      <div id='particles-js' className='particles-container'>
        <Particle />
      </div>
      <div className='header-content pt-5'>
        <div className='container text-center'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='header-texts'>
                <h1 className='display-4'>
                  <span style={{ color: textColor, fontWeight: fontWeight }}>
                    {titleText}{' '}
                  </span>
                  <span>
                    <h1>
                      <span style={titleStyle} className='text-size'>
                        <Typewriter
                          words={typewriterWords}
                          {...typewriterConfig}
                        />
                      </span>
                    </h1>
                  </span>
                </h1>
                <h4 className='product-descrption'>{productsdescription}</h4>
              </div>
            </div>
          </div>
          <div className='row '>
            <div className='col-md-10 offset-md-1'>
              <div className='header-laptop-mockup'>
                <img className='img-fluid' src={imgSrc} alt={imgAlt} loading='lazy' />
              </div>
            </div>
          </div>
        </div>
        <div className='carb-container'>
          <img src={cardImage} className='carb-size' loading='lazy' />
        </div>
      </div>
    </div>
  );
};

export default ProductsDetailsHeader;
