import React from 'react';
import Slider from 'react-slick';
import './ProductDetailsCarousel.css';
import browserBar from '../../assets/img/browser-bar.png';
import { settings } from '../../utils/SliderSettings';
import { ProductCarouselProps } from '../../types/ProductCarousel';

const ProductCarousel: React.FC<ProductCarouselProps> = ({
  images,
  productscarousalheading,
}) => {
  return (
    <>
      <div className='container-fluid'>
        <div className='col-md-6 offset-md-3 col-sm-8  mb-4'>
          <h2 className='d-flex justify-content-center mt-2 gap-2 flex-wrap'>
            Preview<span className='text-secondary'>Our Products</span>
          </h2>
          <p className='d-flex justify-content-center text-center'>
            {productscarousalheading}
          </p>
        </div>
      </div>
      <div className='outer-carsouel'>
        <div className='tab-fix '>
          <img
            src={browserBar}
            alt='software-screen'
            className='software-screens img-fuid'
            loading='lazy'
          />
          <Slider className='has-carousel ' {...settings}>
            {images.map((src, index) => (
              <div className='item' key={index}>
                <img
                  src={src}
                  alt={`software-screen-${index}`}
                  loading='lazy'
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default ProductCarousel;
