import React from 'react';
import { BlogContentProps } from '../../types/BlogContent';

const BlogContent: React.FC<BlogContentProps> = ({ description, content }) => {
  return (
    <div
      className='container col-md-8 col-sm-12 mb-4'
    >
<h5 dangerouslySetInnerHTML={{ __html: description }}></h5>
<h6 dangerouslySetInnerHTML={{ __html: content }}></h6>
      {/* {sections.map((section, index) => (
        <React.Fragment key={index}>
          <h2>{section.title}</h2>
          <p>{section.content}</p>
        </React.Fragment>
      ))} */}
    </div>
  );
};

export default BlogContent;
