import moniter_app from '../../assets/video/moniter-app-video.mov';

export const featuresData = [
  {
    icon: 'bi-display',
    title: 'Dashboard',
    description:
      'A centralized hub offering detailed employee analytics with progress graphs for tracking performance trends and key metrics.',
  },
  {
    icon: 'bi-lightning',
    title: 'Productivity Reports',
    description:
      'Daily working hour reports with activity tracking, time adjustments, and accurate management.',
  },
  {
    icon: 'bi-people',
    title: 'Team Management',
    description:
      'This feature tracks employees and projects, enabling managers to add or remove them for seamless coordination.',
  },
  {
    icon: 'bi-infinity',
    title: 'Leave Management',
    description:
      'This module tracks leave history and balances (PL, Unpaid, Comp-Off), aiding efficient time-off management.',
  },
];


export const aboutContent = {
  title: 'About Monitor App',
  description:
    'MonitorMate is an all-in-one solution for managing employee activities, combining screen monitoring with essential HR tools. It helps organizations boost productivity, ensure accountability, and maintain transparency while supporting employee engagement.',
  listItems: [
    'This leads to inconsistent feedback and poor talent management.',
    'Remote and hybrid work models create new challenges in performance tracking.',
    'Real-time monitoring solutions can enhance productivity.',
    'Provides managers with actionable insights to improve individual and team performance.',
  ],
  videoSrc: moniter_app,
};