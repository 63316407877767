import React, { useState, useEffect } from 'react';
import './TechnologySection.css';
import { ServiceTech, Spinner } from '../AllComponents';
import { TechnologyService } from '../../utils/APIsService/APIsServices';
import { Service } from '../../types/ServiceTypes';
import { TechnologySectionProps } from '../../types/TechnologySectionTypes';

const TechnologySection: React.FC<TechnologySectionProps> = React.memo(
  ({ onTabClick }) => {
    const [services, setServices] = useState<Service[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [activeTab, setActiveTab] = useState<string>('Mobile');

    useEffect(() => {
      const fetchTechnologies = async () => {
        try {
          const response = await TechnologyService.getTechnology();
          if (response.data) {
            setServices(response.data);
            setError(null);
          } else {
            setError('Failed to fetch technology data');
          }
        } catch (err) {
          setError('Failed to fetch technology data');
        } finally {
          setLoading(false);
        }
      };

      fetchTechnologies();
    }, []);

    const handleTabClick = (tab: string, slug: string) => {
      setActiveTab(tab);
      onTabClick(tab, slug);
    };

    return (
      <div className='technology-section'>
        {error || services.length > 0 ? (
          <>
            <section className='tech-navbar'>
              <h2 className='mb-5 text-primary' data-wow-delay='0.1s'>
                Tech-Stack We Use in Software Development
              </h2>
              <ul className='nunito content-fonts'>
                {services.map((tab) => (
                  <li
                    key={tab.id}
                    className={activeTab === tab.name ? 'active' : ''}
                    onClick={() => handleTabClick(tab.name, tab.slug || '')}
                  >
                    {tab.name}
                  </li>
                ))}
              </ul>
            </section>
            <div className='tech-content d-flex api-data-height justify-content-center flex-wrap'>
              {loading ? (
                <div className='text-center position-relative d-flex justify-content-center align-items-center'>
                  <Spinner
                    background='transparent'
                    fixed={false}
                    translate={false}
                  />
                </div>
              ) : (
                services.map(
                  (tab) =>
                    activeTab === tab.name &&
                    tab.technology &&
                    tab.technology.length > 0 &&
                    tab.technology.map((tech) => (
                      <div key={tech.id}>
                        {tab.name === 'Mobile' && (
                          <ServiceTech technologies={[tech]} />
                        )}
                        {tab.name === 'Front-end' && (
                          <ServiceTech technologies={[tech]} />
                        )}
                        {tab.name === 'Back-end' && (
                          <ServiceTech technologies={[tech]} />
                        )}
                        {tab.name === 'Frameworks' && (
                          <ServiceTech technologies={[tech]} />
                        )}
                      </div>
                    ))
                )
              )}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    );
  }
);

export default TechnologySection;
