import React, { CSSProperties } from 'react';
import { AboutSectionProps } from '../../types/AboutSection';

const AboutSection: React.FC<AboutSectionProps> = ({
  imgSrc,
  imgAlt,
  title,
  className,
  children,
  isImageLeft = true,
  isReversed = false,
}) => {
  const backgroundImageStyle: CSSProperties = isReversed
    ? {
        objectFit: 'cover' as const,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }
    : {};

  return (
    <section
      className={`about-section py-5 ${isReversed ? 'reversed' : ''}`}
      style={backgroundImageStyle}
    >
      <div className='container'>
        <div
          className={`row align-items-center ${
            isImageLeft ? '' : 'flex-row-reverse'
          }`}
        >
          {imgSrc && (
            <div className='col-lg-6 text-center mb-4 mb-lg-0'>
              <img
                src={imgSrc}
                alt={imgAlt}
                className='img-fluid w-75 h-75 animate__zoomIn wow'
                data-wow-delay='0.1s'
                loading='lazy'
              />
            </div>
          )}
          <div
            className={`col-lg-6 text-left wow animate__pulse`}
            data-wow-delay='0.1s'
          >
            <h2 className={className}>{title}</h2>
            {children}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
