import React, { useState } from 'react';
import { Accordion } from '../../components/AllComponents';
import { ProductsDetailsFaqProps } from '../../types/ProductsDetailsFaq';


const ProductsDetailsFaq: React.FC<ProductsDetailsFaqProps> = ({
  backgroundGradient,
  heading ,
  subheading ,
  paragraph ,
  items
}) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const handleAccordionClick = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div
      className="container-fluid FAQ overflow-hidden py-5"
      style={{ background: backgroundGradient }}
    >
      <div className="container py-5">
        <div className="row g-5 align-items-center">
          <div className="col-lg-6 wow animate__zoomIn" data-wow-delay="0.1s">
            <div className="side-heading text-white">
              <h2 className="products-faq-heading">
                {heading} <span>{subheading}</span>
              </h2>
              {paragraph && <h5 className="faq-paragraph">{paragraph}</h5>}
            </div>
          </div>
          <div className="col-lg-6 wow animate__pulse" data-wow-delay="0.1s">
              <Accordion
                items={items}
                activeIndex={activeIndex}
                onAccordionClick={handleAccordionClick}
              />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsDetailsFaq;
