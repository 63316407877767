import MobileIcon from '../../assets/Icons/MobileAppIcon';
import WebDevelopmentIcon from '../../assets/Icons/WebDevelopmentIcon';
import IotDevelopmentIcon from '../../assets/Icons/IotDevelopmentIcon';
import WebRTCIcon from '../../assets/Icons/WebRTCIcon';
import DevOpsIcon from '../../assets/Icons/DevOpsIcon';
import UiUxIcon from '../../assets/Icons/UiUxIcon';
import { ServiceData } from '../../types/icon';


export const serviceData: ServiceData[] = [
  {
    image: WebDevelopmentIcon,
    title: 'Web Development',
    backgroundcolor: '#fcf7ee',
    description:
      'Mayora Infotech offers top-tier web development services designed to create customized websites, web applications, and digital tools. Our team focuses on delivering high-performance, scalable, and aesthetically appealing solutions that cater to your specific business needs, ensuring a seamless user experience.',
  },
  {
    image: MobileIcon,
    title: 'Mobile App Development',
    backgroundcolor: '#edf1fe',
    description:
      'Stay ahead in the mobile-first era with our expertly crafted mobile app solutions for Android, iOS, and cross-platform environments. We specialize in building feature-rich, user-friendly, and highly functional apps that enhance user engagement and deliver business value.',
  },
  {
    image: IotDevelopmentIcon,
    title: 'IOT Development',
    backgroundcolor: '#f0f5f4',
    description:
      'Unleash the power of connected devices with our Internet of Things (IoT) solutions. From smart home systems to industrial IoT applications, we deliver secure and scalable solutions that transform the way you interact with technology, helping businesses innovate and optimize operations.',
    animation: 'fadeInUp',
  },
  {
    image: WebRTCIcon,
    title: 'WebRTC',
    backgroundcolor: '#fef5f6',
    description:
      'Enable real-time communication capabilities with our WebRTC development services. Whether it is video conferencing, live streaming, or peer-to-peer data sharing, we create robust and secure solutions that elevate user interaction and engagement.',
  },
  {
    image: DevOpsIcon,
    title: 'DevOps',
    backgroundcolor: '#fdf5fe',
    description:
      'Accelerate your development lifecycle with our DevOps services. We streamline workflows, enhance collaboration, and optimize your IT infrastructure to ensure faster and more efficient delivery of applications and updates.',
  },
  {
    image: UiUxIcon,
    title: 'UI/UX Design',
    backgroundcolor: '#fefaf5',
    description:
      'Create visually stunning and user-centric digital experiences with our UI/UX design services. We focus on intuitive interfaces and engaging designs that align with your brand and ensure a seamless journey for your users.',
  },
];
