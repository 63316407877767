import React from 'react';
import { BlogHeaderprops } from '../../types/BlogHeader';

const BlogHeader: React.FC<BlogHeaderprops> = (
  { tags , title = 'Blog', author = 'Unknown', authorImg, blogImg }
) => {
  const formatTags = (tags: string | string[]): string[] => {
    if (typeof tags === 'string') {
      return tags.split(',').map((tag) => tag.trim());
    }
    return tags as string[];
  };

  const formattedTags = Array.isArray(tags) ? tags : formatTags(tags);

  return (
    <div className='container mt-5 py-5'>
      <div className='row align-items-center'>
        <div className='col-md-8'>
          <div className='d-flex align-items-center mb-3'>
            <div className='me-2 text-secondary'>
              <i className='bi bi-tag' style={{ fontSize: '1.2rem' }}></i>
            </div>
            <p className='mb-0'>
            {formattedTags.map((tag, index) => (
          <span
            key={index}
            className={`badge bg-primary me-2 mb-2 text-light`}
            style={{ display: 'inline-block', marginRight: '8px' }}
          >
            {tag}
          </span>
        ))}
            </p>
          </div>
          <h1 className=' fw-bold mb-4'>{title}</h1>
          <div className='d-flex align-items-center mb-4'>
            <img
              src={authorImg || 'https://static.thenounproject.com/png/4595376-200.png'}
              alt='Author'
              className='rounded-circle me-3'
              style={{ width: '50px', height: '50px' }}
              loading='lazy'
            />
            <div>
              <p className='mb-0 fw-bold'>Author</p>
              <h6 className='mb-0'>{author}</h6>
            </div>
          </div>
        </div>
        <div className='col-md-4 text-center'>
          <img
            src={blogImg || 'https://static.thenounproject.com/png/4595376-200.png'}
            alt='Blog Illustration'
            className='img-fluid rounded'
            loading='lazy'
          />
        </div>
      </div>
    </div>
  );
};

export default BlogHeader;
