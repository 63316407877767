import { isValidPhoneNumber } from 'libphonenumber-js';
import * as Yup from 'yup';

 export const jobApplicationFormValidationSchema = Yup.object({
  first_name: Yup.string()
    .matches(/^[a-zA-Z\s]*$/, 'First name can only contain letters and spaces')
    .required('First name is required'),
  last_name: Yup.string()
    .matches(/^[a-zA-Z\s]*$/, 'Last name can only contain letters and spaces')
    .required('Last name is required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  phone: Yup.string()
    .required('Phone number is required')
    .test(
      'is-valid-phone',
      'Invalid phone number format. Please check the country code and phone number.',
      (value) => {
        return isValidPhoneNumber(value);
      }
    ),
  skill: Yup.string().required('Skill is required'),
  current_position: Yup.string().required('Position is required'),
  education: Yup.string().required('Education is required'),
  company_name: Yup.string().required('Company Name is required'),
  total_experience: Yup.string()
    .matches(
      /^(0|[1-9]|1[0-4]|15)(\.\d{1})?\s?(month|months|year|years)?$/,
      'Invalid format. follow this e.g (1 or 1.5 year)'
    )
    .required('Total experience is required'),
  relevant_experience: Yup.string()
    .matches(
      /^(0|[1-9]|1[0-4]|15)(\.\d{1})?\s?(month|months|year|years)?$/,
      'Invalid format. follow this e.g (1 or 1.5 year)'
    )
    .required('Relevant experience is required'),
  current_ctc: Yup.string()
    .matches(
      /^(?:[1-9]\d{0,7}(?:\.\d+)?|\.\d+)\s*(crores?|cr|lakh(s)?|thousands?)?$/,
      'Invalid format. Follow this example: 4.5 crore, 1 lakh, 50000 thousands, etc.'
    )
    .required('current_ctc CTC is required'),
  expected_ctc: Yup.string()
    .matches(
      /^(?:[1-9]\d{0,7}(?:\.\d+)?|\.\d+)\s*(crores?|cr|lakh(s)?|thousands?)?$/,
      'Invalid format. Follow this example: 4.5 crore, 1 lakh, 50000 thousands, etc.'
    )
    .required('Expected CTC is required'),
  notice_period: Yup.string()
    .matches(
      /^(?:[1-9][0-9]{0,4})(?:\s*(day(s)?|month(s)?|week(s)?))?$/,
      'Invalid format.follow this e.g (3 months, 2 weeks, 15 days)'
    )
    .required('Notice period is required'),
  current_location: Yup.string().required('current_ctc location is required'),
  uploded_cv: Yup.mixed()
    .required('CV is required')
    .test(
      'fileSize',
      'File size too large. Maximum size is 5MB.',
      (value: any) => value && value.size <= 5 * 1024 * 1024
    )
    .test(
      'fileFormat',
      'Only PDF files are allowed.',
      (value: any) => value && value.type === 'application/pdf'
    ),
  cover_letter: Yup.mixed()
    .required('Cover letter is required')
    .test(
      'fileSize',
      'File size too large. Maximum size is 4MB.',
      (value: any) => value && value.size <= 4 * 1024 * 1024
    )
    .test(
      'fileFormat',
      'Only PDF, JPG, or PNG files are allowed.',
      (value: any) =>
        value &&
        ['application/pdf', 'image/jpeg', 'image/png'].includes(value.type)
    ),
});
