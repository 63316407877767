import React from 'react';
import './About.css';
import {
  Breadcrumb,
  UseMetaTags,
  AboutSection,
  InfoHeader,
} from '../../components/AllComponents';
import { aboutData } from '../../utils/MockData/About';
import { industries } from '../../assets/Icons/Icon';

const About: React.FC = () => {
  UseMetaTags({
    title:
      'About Mayora Infotech - Leading IT Services & Mobile App Development',
    description:
      'Learn more about Mayora Infotech, a top IT services company in Ahmedabad. We specialize in mobile app development, IoT, and software solutions.',
    keywords:
      'Mayora Infotech, IT services Ahmedabad, mobile app development India, IoT solutions.',
    author: 'Mayora Infotech',
    language: 'English',
    ogtitle: 'Mayora Infotech - Leading IT Services & Solutions Provider',
    ogdescription:
      'Mayora Infotech specializes in providing IT services such as web development, digital marketing, software solutions, and IT consulting.',
    ogImage: '%PUBLIC_URL%/favicon.ico',
    ogtype: 'website',
    ogurl: 'https://mayorainfotech.com/about/',
    canonical: 'https://mayorainfotech.com/',
  });

  const getTitleWithSpan = (title: string) => {
    const titleWords = title.split(' ');
    const firstPart = titleWords[0];
    const secondWord = titleWords[1] || '';
    const remainingTitle = titleWords.slice(2).join(' ');

    return (
      <>
        {firstPart} <span className='highlight'>{secondWord}</span>{' '}
        {remainingTitle}
      </>
    );
  };

  return (
    <>
      <Breadcrumb title='About Us'  currentPage='About Us' currentPath='/about-us' />
      <section className=''>
        <section className='about-section'>
          <InfoHeader
            title='Empowering Clients with Cutting-Edge Solutions'
            description='Mayora Infotech provides groundbreaking technological solutions tailored to businesses across various sizes and sectors. Catering to a diverse clientele, we extend a spectrum of services to support our clients in achieving success, ranging from established global brands to emerging startups.'
          />
        </section>
        <AboutSection
          imgSrc={aboutData.story.imgSrc}
          imgAlt={aboutData.story.imgAlt}
          title={getTitleWithSpan(aboutData.story.title)}
          className='nunito '
          isImageLeft={false}
        >
          <span className='content-fonts'>{aboutData.story.content}</span>
        </AboutSection>
        <AboutSection
          imgSrc={aboutData.mission.imgSrc}
          imgAlt={aboutData.mission.imgAlt}
          title={getTitleWithSpan(aboutData.mission.title)}
          className='nunito '
          isImageLeft
        >
          <span className='content-fonts'>{aboutData.mission.content}</span>
        </AboutSection>
        <AboutSection
          imgSrc={aboutData.vision.imgSrc}
          imgAlt={aboutData.vision.imgAlt}
          title={getTitleWithSpan(aboutData.vision.title)}
          className='nunito '
          isImageLeft={false}
        >
          <span className='content-fonts'>{aboutData.vision.content}</span>
        </AboutSection>
        <AboutSection
          imgSrc={aboutData.culture.imgSrc}
          imgAlt={aboutData.culture.imgAlt}
          title={getTitleWithSpan(aboutData.culture.title)}
          className='nunito '
          isImageLeft
        >
          <span className='content-fonts'>{aboutData.culture.content}</span>
        </AboutSection>
      
        <section className='industries-section py-5'>
          <div className='container'>
            <h2
              className='text-center text-primary mb-5 wow fadeInDown nunito'
              data-wow-delay='0.1s'
            >
              Serving Our Clients Across Diverse Industries
            </h2>
            <div className='row'>
              {industries.map((industry, index) => (
                <div
                  key={index}
                  className='col-sm-6 col-md-4 col-lg-3 mb-2 wow fadeInUp '
                  data-wow-delay={`${0.1 * index}s`}
                >
                  <div className='industry-card d-flex flex-column align-items-center justify-content-center'>
                    <span className='icon-container mb-2'>{industry.icon}</span>
                    <span className='industry-name mb-0 nunito'>
                      {industry.name}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default About;
