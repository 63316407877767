import React from 'react';
import {
  BlogCard,
  Breadcrumb,
  DataNotFound,
  DevelopmentProcessCard,
  Spinner,
  TechnologyAboutServicesCard,
  TechnologyDetailsHeader,
  TechnologySkills,
  useFetch,
} from '../../components/AllComponents';
import technologyImg from '../../assets/img/technology-image.png';
import { skills } from '../../utils/MockData/Skills';
import { BlogType, TechnologyDetailsApiResponse } from '../../types/APIs';
import {
  BlogsService,
  TechnologyDetailsService,
} from '../../utils/APIsService/APIsServices';
import { useNavigate, useParams } from 'react-router-dom';
import {
  developmentprocess,
  features,
  technologycard,
} from '../../utils/MockData/TechnologyDetails';

const TechnologyDetails = () => {
  const { slug } = useParams<{ slug: string }>();

  const {
    data: technology,
    loading: techLoading,
    error: techError,
  } = useFetch<TechnologyDetailsApiResponse>(async () => {
    if (!slug) {
      throw new Error('Slug is missing');
    }
    const response = await TechnologyDetailsService.getTechnologyService(slug);
    return response;
  }, true);

  const technologyData = technology?.data;
  console.log('=====technologyData', technologyData);

  const {
    data: blogs,
    loading: blogsLoading,
    error: blogsError,
  } = useFetch<BlogType[]>(async () => {
    const response = await BlogsService.getBlogs();
    return response.data;
  }, true);

  const blogsData = blogs || [];
  const navigate = useNavigate();

  const hangleBlogsClick = (slug: string) => {
    navigate(`/blog/blog-details/${slug}`);
  };

  const extractPointsFromDescription = (description: string): string[] => {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(description, 'text/html');
    const listItems = htmlDoc.querySelectorAll('li');
    return Array.from(listItems).map((item) => item.textContent?.trim() || '');
  };

  return (
    <>
      <Breadcrumb
        apititle={technology?.data?.name || 'Service Details'}
        prevPage='Services'
        prevPath='/services'
        currentPage={technology?.data?.name ? technology.data.name : undefined}
        currentPath={
          technology?.data?.slug
            ? `/service/detail/${technology.data.slug}`
            : '/'
        }
      />
      {techLoading || blogsLoading ? (
        <div className='text-center position-relative h-50'>
          <Spinner background='transparent' fixed={false} translate={false} />
        </div>
      ) : techError || !technologyData ? (
        <div className='col-12 text-center'>
          <DataNotFound />
        </div>
      ) : (
        <>
          <TechnologyDetailsHeader
            title={technology?.data.name}
            description={technology?.data.description}
            imageSrc={technology?.data.full_banner_image_url}
          />
          <TechnologyAboutServicesCard
            technologycard={technology?.data.driven || technologycard}
            servicecardheading={technology?.data.name}
            servicecardheadingsummary={technology?.data.name}
          />
          <TechnologySkills skills={technology?.data.skills || skills} />
          <DevelopmentProcessCard
            developmentproccesssummary={technology?.data.name}
            // process={
            //   technology?.data.development_processes?.map((process: any) => ({
            //     id: process.id,
            //     name: process.name,
            //     points: extractPointsFromDescription(process.description),
            //   })) || []
            // }
            developmentprocess= {technology?.data.development_processes}
            title={technology?.data.name}
          />
          <div className='row g-3 mt-5 justify-content-center api-data-height'>
            {blogsData.map((blog, index) => (
              <BlogCard
                key={blog.id}
                id={blog.id}
                slug={blog.slug}
                image={blog.image}
                title={blog.title}
                description={blog.descraption}
                date={new Date(blog.created_at).toLocaleDateString()}
                animationDelay={`${index * 0.2}s`}
                onClick={() => hangleBlogsClick(blog.slug)}
              />
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default TechnologyDetails;
